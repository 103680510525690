import React from 'react';

export const config1 = () => {
  return (
    <div>config 1</div>
  );
};

export const config2 = () => {
  return (
    <div>config 2</div>
  );
};


export const awsKeyId="AKIAZQ3DRSHHVU4PX7GO"
export const awsSecretKey="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"

export const apiurl = "https://tridentlifescience-engine.onrender.com"
// export const apiurl = "http://localhost:8080"