import React from 'react';
import Layout from '../components/layout';
import { LiaStarSolid } from "react-icons/lia";
// import CourseImage from './CourseImage'; // Import a sample course image

const Orders = () => {
  // Sample data for purchase history
  const purchaseHistory = [
    {
      id: '123456',
      courseName: 'Introduction to React',
      instructor: 'John Doe',
      price: '$49.99',
      purchaseDate: '2024-04-28',
      status: 'Completed',
    },
    {
      id: '789012',
      courseName: 'Advanced JavaScript',
      instructor: 'Jane Smith',
      price: '$59.99',
      purchaseDate: '2024-04-25',
      status: 'Completed',
    },
    // Add more purchase history items here
  ];

  return (
    <Layout>
    <div className="bg-gray-100 min-h-screen py-8">
      <h1 className="text-3xl font-semibold text-center mb-6">Purchase History</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 max-w-6xl mx-auto">
        {purchaseHistory.map((purchase) => (
          <div
            key={purchase.id}
            className="bg-white shadow-md rounded-lg overflow-hidden hover:shadow-lg transition duration-300"
          >
            <div className="relative">
              {/* Course Image */}
              {/* <CourseImage className="w-full h-40 obj÷ect-cover" /> */}
              <img src='/' className="w-full h-40 object-cover" />
              
              {/* Status Indicator */}
              <div className="absolute top-2 right-2">
                <span
                  className={`px-2 py-1 text-xs font-semibold ${
                    purchase.status === 'Completed' ? 'bg-green-500 text-white' : 'bg-blue-500 text-white'
                  } rounded-full`}
                >
                  {purchase.status}
                </span>
              </div>
            </div>
            <div className="p-4">
              {/* Course Name */}
              <h2 className="text-xl font-semibold mb-2">{purchase.courseName}</h2>
              {/* Instructor */}
              <p className="text-gray-600 mb-2">Instructor: {purchase.instructor}</p>
              {/* Price */}
              <p className="text-gray-600 mb-2">Price: {purchase.price}</p>
              {/* Purchase Date */}
              <p className="text-gray-600 mb-2">Purchase Date: {purchase.purchaseDate}</p>
            </div>
          </div>
        ))}
      </div>
    </div>




    <div className="p-4 bg-white">
                <div className=" rounded-xl lg:flex justify-between items-center sm:flex-row">
                    <div className="flex justify-between w-full lg:w-3/5 xl:w-[45%] flex-col sm:flex-row sm:w-11/12">
                        <div className=" w-full xl:w-64 sm:w-64">
                            <img src="/assets/community.jpg" alt='' className="w-full" />
                        </div>
                        <div className="mt-6 sm:mt-0">
                            <p className="text-[#005AE6] font-bold text-lg">Sound City: Real to Reel</p>
                            <p className="text-lg">by Sound City Real to Reel (audio CD)</p>
                            <div className="flex items-center">
                                <LiaStarSolid className="text-[#f1c65c]" />
                                <LiaStarSolid className="text-[#f1c65c]" />
                                <LiaStarSolid className="text-[#f1c65c]" />
                                <LiaStarSolid className="text-[#f1c65c]" />
                                <span className="text-[#005AE6] ml-2">(141)</span>
                            </div>
                            <p className="text-red-600 font-bold">$11.12</p>
                            <p className="text-lg">In Stoks offers of by Community</p>
                            <p className="text-sm font-bold text-[#005AE6]">70 Used & New <span className="text-black">from</span> <span className="text-red-600">$10.13</span></p>
                            <p className="text-[#005AE6] mt-3 font-bold text-lg">Add comments, quantity & priority</p>
                        </div>
                    </div>
                    <div className="lg:w-3/12 lg:m-0 sm:w-11/12 sm:m-auto sm:mt-0 w-full mt-8">
                        <h3 className="mb-2 font-bold">Added December 19, 2023</h3>
                        <div className="text-center bg-[#f1c65c] px-8 py-2 rounded-md">
                            <button className="text-lg font-semibold"> Add to Cart</button>
                        </div>
                        <div className="flex justify-between gap-1 mt-3">
                            <div className="bg-gray-200 w-full py-2 text-center rounded-md">
                                <button className="font-semibold">Move</button>
                            </div>
                            <div className="bg-gray-200 w-full py-2 text-center rounded-md">
                                <button className="">Delete</button>
                            </div>
                        </div>
                    </div>
                </div>
                <hr className="border-t-2 border-gray-300 my-10 w-11/12 mx-auto h-2" />
            </div>


            <div className="p-4 bg-white">
                <div className=" rounded-xl lg:flex justify-between items-center sm:flex-row">
                    <div className="flex justify-between w-full lg:w-3/5 xl:w-[45%] flex-col sm:flex-row sm:w-11/12">
                        <div className=" w-full xl:w-64 sm:w-64">
                            <img src="/assets/community.jpg" alt='' className="w-full" />
                        </div>
                        <div className="mt-6 sm:mt-0">
                            <p className="text-[#005AE6] font-bold text-lg">Sound City: Real to Reel</p>
                            <p className="text-lg">by Sound City Real to Reel (audio CD)</p>
                            <div className="flex items-center">
                                <LiaStarSolid className="text-[#f1c65c]" />
                                <LiaStarSolid className="text-[#f1c65c]" />
                                <LiaStarSolid className="text-[#f1c65c]" />
                                <LiaStarSolid className="text-[#f1c65c]" />
                                <span className="text-[#005AE6] ml-2">(141)</span>
                            </div>
                            <p className="text-red-600 font-bold">$11.12</p>
                            <p className="text-lg">In Stoks offers of by Community</p>
                            <p className="text-sm font-bold text-[#005AE6]">70 Used & New <span className="text-black">from</span> <span className="text-red-600">$10.13</span></p>
                            <p className="text-[#005AE6] mt-3 font-bold text-lg">Add comments, quantity & priority</p>
                        </div>
                    </div>
                    <div className="lg:w-3/12 lg:m-0 sm:w-11/12 sm:m-auto sm:mt-0 w-full mt-8">
                        <h3 className="mb-2 font-bold">Added December 19, 2023</h3>
                        <div className="text-center bg-[#f1c65c] px-8 py-2 rounded-md">
                            <button className="text-lg font-semibold"> Add to Cart</button>
                        </div>
                        <div className="flex justify-between gap-1 mt-3">
                            <div className="bg-gray-200 w-full py-2 text-center rounded-md">
                                <button className="font-semibold">Move</button>
                            </div>
                            <div className="bg-gray-200 w-full py-2 text-center rounded-md">
                                <button className="">Delete</button>
                            </div>
                        </div>
                    </div>
                </div>
                <hr className="border-t-2 border-gray-300 my-10 w-11/12 mx-auto h-2" />
            </div>

            <div className="p-4 bg-white">
                <div className=" rounded-xl lg:flex justify-between items-center sm:flex-row">
                    <div className="flex justify-between w-full lg:w-3/5 xl:w-[45%] flex-col sm:flex-row sm:w-11/12">
                        <div className=" w-full xl:w-64 sm:w-64">
                            <img src="/assets/community.jpg" alt='' className="w-full" />
                        </div>
                        <div className="mt-6 sm:mt-0">
                            <p className="text-[#005AE6] font-bold text-lg">Sound City: Real to Reel</p>
                            <p className="text-lg">by Sound City Real to Reel (audio CD)</p>
                            <div className="flex items-center">
                                <LiaStarSolid className="text-[#f1c65c]" />
                                <LiaStarSolid className="text-[#f1c65c]" />
                                <LiaStarSolid className="text-[#f1c65c]" />
                                <LiaStarSolid className="text-[#f1c65c]" />
                                <span className="text-[#005AE6] ml-2">(141)</span>
                            </div>
                            <p className="text-red-600 font-bold">$11.12</p>
                            <p className="text-lg">In Stoks offers of by Community</p>
                            <p className="text-sm font-bold text-[#005AE6]">70 Used & New <span className="text-black">from</span> <span className="text-red-600">$10.13</span></p>
                            <p className="text-[#005AE6] mt-3 font-bold text-lg">Add comments, quantity & priority</p>
                        </div>
                    </div>
                    <div className="lg:w-3/12 lg:m-0 sm:w-11/12 sm:m-auto sm:mt-0 w-full mt-8">
                        <h3 className="mb-2 font-bold">Added December 19, 2023</h3>
                        <div className="text-center bg-[#f1c65c] px-8 py-2 rounded-md">
                            <button className="text-lg font-semibold"> Add to Cart</button>
                        </div>
                        <div className="flex justify-between gap-1 mt-3">
                            <div className="bg-gray-200 w-full py-2 text-center rounded-md">
                                <button className="font-semibold">Move</button>
                            </div>
                            <div className="bg-gray-200 w-full py-2 text-center rounded-md">
                                <button className="">Delete</button>
                            </div>
                        </div>
                    </div>
                </div>
                <hr className="border-t-2 border-gray-300 my-10 w-11/12 mx-auto h-2" />
            </div>

        
    </Layout>
  );
};

export default Orders;
