import React, { useState, useEffect, useRef } from "react";
import Layout from "../components/layout";
import { Link } from "react-router-dom";
import QRCode from "qrcode.react";
import { useUser } from "../config/UserProvider";

const Dashboard = () => {
  const [activeTab, setActiveTab] = useState(1);
  const { userData } = useUser();

  console.log("userData",userData);

  const changeTab = (tabNumber) => {
    setActiveTab(tabNumber);
  };

  const [isOpen, setIsOpen] = useState(false);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  // const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const currentDate = new Date().toLocaleDateString();
  const certificateNumber = "12345";
  const partnerLogoUrl = "https://example.com/partner_logo.png";
  const qrCodeData = "https://example.com/certificate";

  return (
    <Layout>
      <div className="custom-max-width m-auto">
        <div className="bg-purple-100 p-6 rounded-lg shadow-lg my-2">
          <h2 className="text-xl font-bold text-purple-800">
            WELCOME BACK! {userData?.firstname}
          </h2>
          <p className="mt-2 text-purple-600">
            You have completed 60% of your goal this week! Start a new goal and
            improve your result
          </p>
          <button className="mt-4 bg-purple-600 text-white px-4 py-2 rounded">
            Learn More
          </button>
        </div>
        <div className="w-full flex items-center">
          <div className="max-w-screen-2xl flex md:flex-row flex-col justify-between w-full p-4">
            <div className="bg-gray-100 py-6 w-full">
              <div className="">
                <div className="flex border-b border-gray-200">
                  <button
                    className={`${
                      activeTab === 1
                        ? "border-blue-500 text-blue-700"
                        : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300"
                    } py-2 px-4 inline-flex items-center border-b-2 font-medium text-sm focus:outline-none`}
                    onClick={() => changeTab(1)}
                  >
                    Home
                  </button>
                  <button
                    className={`${
                      activeTab === 2
                        ? "border-blue-500 text-blue-700"
                        : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300"
                    } py-2 px-4 inline-flex items-center border-b-2 font-medium text-sm focus:outline-none`}
                    onClick={() => changeTab(2)}
                  >
                    My Courses
                  </button>
                  <button
                    className={`${
                      activeTab === 3
                        ? "border-blue-500 text-blue-700"
                        : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300"
                    } py-2 px-4 inline-flex items-center border-b-2 font-medium text-sm focus:outline-none`}
                    onClick={() => changeTab(3)}
                  >
                    My Certificate
                  </button>
                </div>
                <div className="mt-6">
                  {activeTab === 1 && (
                    <div>
                      <div class="max-w-sm bg-white border border-gray-200 rounded-lg shadow ">
                        <a href="#">
                          <img
                            class="rounded-t-lg"
                            src="/docs/images/blog/image-1.jpg"
                            alt=""
                          />
                        </a>
                        <div class="p-5">
                          <a href="#">
                            <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                              Noteworthy technology acquisitions 2021
                            </h5>
                          </a>
                          <p class="mb-3 font-normal text-gray-700 dark:text-gray-400">
                            Here are the biggest enterprise technology
                            acquisitions of 2021 so far, in reverse
                            chronological order.
                          </p>
                          <a
                            href="#"
                            class="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                          >
                            Read more
                            <svg
                              class="rtl:rotate-180 w-3.5 h-3.5 ms-2"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 14 10"
                            >
                              <path
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M1 5h12m0 0L9 1m4 4L9 9"
                              />
                            </svg>
                          </a>
                        </div>
                      </div>

                      <p>This is the content of Tab 1.</p>
                    </div>
                  )}
                  {activeTab === 2 && (
                    <div>
                      <a
                        href="#"
                        class="flex flex-col items-center bg-white border border-gray-200 rounded-lg shadow md:flex-row md:max-w-xl hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700"
                      >
                        <img
                          class="object-cover w-full rounded-t-lg h-96 md:h-auto md:w-48 md:rounded-none md:rounded-s-lg"
                          src="/docs/images/blog/image-4.jpg"
                          alt=""
                        />
                        <div class="flex flex-col justify-between p-4 leading-normal">
                          <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                            Noteworthy technology acquisitions 2021
                          </h5>
                          <p class="mb-3 font-normal text-gray-700 dark:text-gray-400">
                            Here are the biggest enterprise technology
                            acquisitions of 2021 so far, in reverse
                            chronological order.
                          </p>
                        </div>
                      </a>

                      <p>This is the content of Tab 2.</p>
                    </div>
                  )}
                  {activeTab === 3 && (
                    <div>
                      <Link
                        to="/course"
                        className="card flex flex-col items-center w-[22rem] bg-white rounded-xl shadow-lg overflow-hidden"
                      >
                        <img src="/" className="bg-green-50 h-52 w-full " />
                        <div className="flex items-center gap-3 bg-gray-50 rounded-full -mt-5 px-3 pr-4 py-2 font-medium text-sm">
                          <div className="flex flex-row ">
                            <span className="bg-yellow-400 rounded-full w-8 h-8  "></span>
                            <span className="bg-red-400 rounded-full w-8 h-8 -ml-3 "></span>
                            <span className="bg-green-400 rounded-full w-8 h-8 -ml-3"></span>
                            <span className="bg-violet-400 rounded-full w-8 h-8 -ml-3"></span>
                          </div>
                          +40 Students
                        </div>
                        <div className="py-7 px-2 w-11/12">
                          <h5 className="font-semibold text-lg tracking-wide px-1">
                            title
                          </h5>
                          <p className="text-xs text-gray-400 mt-2 ">
                            fjk fjdj
                          </p>
                          <div className="flex justify-between items-center w-full mt-5">
                            <div>
                              <span className="font-bold text-xl text-orange-500">
                                offerPrice
                              </span>
                              <span className="line-through text-lg text-gray-400">
                                1900
                              </span>
                            </div>
                            <div className="bg-orange-400/30 p-2 rounded-full">
                              {/* <LiaShoppingCartSolid className="text-3xl text-orange-400" /> */}
                            </div>
                          </div>
                        </div>
                      </Link>
                      <p>This is the content of Tab 3.</p>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="mt-6 md:w-1/4 w-full">
              <div className="mt-6 flex flex-col">
                <div className="bg-white p-6 rounded-lg shadow-lg h-80 overflow-y-auto my-5">
                  <h3 className="text-lg font-semibold">Upcoming Events</h3>
                  <ul className="mt-4 space-y-4">
                    <li className="bg-gray-100 p-4 rounded-lg">
                      <h4 className="font-semibold">Discussion Algorithm</h4>
                      <p className="text-gray-600">08:00 AM - 12:00 PM</p>
                    </li>
                    <li className="bg-gray-100 p-4 rounded-lg">
                      <h4 className="font-semibold">Fundamental Math</h4>
                      <p className="text-gray-600">12:00 PM - 03:00 PM</p>
                    </li>
                    <li className="bg-gray-100 p-4 rounded-lg">
                      <h4 className="font-semibold">
                        DNA Modifications in Humans
                      </h4>
                      <p className="text-gray-600">Ongoing</p>
                    </li>
                    {/* Add more tasks as needed */}
                  </ul>
                </div>

                <div className="bg-white p-6 rounded-lg shadow-lg h-80 overflow-y-auto">
                  <h3 className="text-lg font-semibold">Latest News</h3>
                  <ul className="mt-4 space-y-4">
                    <li className="bg-gray-100 p-4 rounded-lg">
                      <h4 className="font-semibold">News Item 1</h4>
                      <p className="text-gray-600">
                        Description for news item 1.
                      </p>
                    </li>
                    <li className="bg-gray-100 p-4 rounded-lg">
                      <h4 className="font-semibold">News Item 2</h4>
                      <p className="text-gray-600">
                        Description for news item 2.
                      </p>
                    </li>
                    <li className="bg-gray-100 p-4 rounded-lg">
                      <h4 className="font-semibold">News Item 3</h4>
                      <p className="text-gray-600">
                        Description for news item 3.
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Dashboard;
