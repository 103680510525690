import React from "react";
import "./App.css";
import RoutePage from "./routes";
import { UserProvider } from "./config/UserProvider";

function App() {
  return (
    <>
      <UserProvider>
        <RoutePage />
      </UserProvider>
    </>
  );
}

export default App;
