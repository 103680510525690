import React, { useEffect, useState } from "react";
import axios from "axios";
import Header from "../components/header";
import Layout from "../components/layout";
import { Link } from "react-router-dom";
import { apiurl } from "../config/config";
import TopBanner from "../components/topBanner";
import { getCookie } from "../config/coockies";
import LoaderSpiner from "../components/loader";
import OurPartners from "../components/ourPartners";

const Home = () => {
  const discussiondata = [];
  const blogdata = [];

  const [discussion, setDiscussion] = useState(discussiondata);
  const [blog, setblog] = useState(blogdata);
  const [courses, setCourses] = useState([]);
  const token = getCookie("savage_token");
  const [loading, setLoading] = useState(false);

  const fetchCourses = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${apiurl}/course/all`,
      headers: {
        "Content-Type": "application/json",
      },
    };
    axios
      .request(config)
      .then((response) => {
        setCourses(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    fetchCourses();
  }, []);

  return (
    <>
      {loading === true ? <LoaderSpiner text="Loading ..." /> : null}
      <Layout>
        <div className="w-full flex flex-col items-center">
          <div className="max-w-screen-2xl h-full flex justify-between items-center p-4 custom-max-width">
            <div className="w-full md:w-[1/2]">
              <div className="capitalize text-[39px] font-[600] md:text-[69px]">
                The
                <span className="text-orange-500"> smart </span>
                <br />
                choice for
                <span> future</span>
              </div>
              <p className="text-[#8A8A8A] w-11/12 text-xl">
              <span className="text-orange-500">Trident Life Science</span> is a global training provider specializing in accredited and customized training courses. We excel at...
              </p>
              {/* <div className="bg-white rounded-full overflow-hidden pl-5 w-10/12 mt-10"> */}

              <div className="my-6 flex flex-col md:flex-row items-start">
                {/* <input
                 
                    name="location"
                    placeholder="Search for a location..."
                    className="py-1 md:py-5 text-xl w-full"
                  /> */}
                <Link
                  to="/courses"
                  className="bg-[#4D2C5E] hover:bg-[#FF7426] text-xl text-white font-semibold rounded-full py-2 md:py-3 px-7 m-2 ml-0"
                >
                  View Courses
                </Link>
                <Link
                  to="/register"
                  className="hover:bg-[#FF7426] text-xl text-[#ff7426] hover:text-black font-semibold rounded-full py-2 md:py-3 px-7 m-2 ml-0 md:ml-2 border border-[#ff7426]"
                >
                  Get Started
                </Link>
              </div>
              {/* </div> */}
            </div>
            <div className="w-full hidden md:block">
              <img src="/herobanner.png" />
            </div>
          </div>
        </div>

        <div class="bg-[#fff]">
          <div class="m-auto py-16 my-20 flex flex-col items-center custom-max-width md:flex-row md:justify-center">
            <div class="w-full md:w-1/2 h-full flex justify-center items-center md:order-1">
              <div>
                <img
                  src="/learning.png"
                  alt="section img"
                  class="h-48 md:h-80"
                />
              </div>
            </div>
            <div class="flex flex-col gap-5 w-full md:w-1/2 md:order-2">
              <div class="capitalize text-[30px] md:text-[69px] font-[600] leading-none text-center md:text-left">
                Premium <span class="text-orange-500">Learning </span>experience
              </div>
              <div class="flex flex-col items-center md:items-start gap-5 mt-5">
                {/* <div class="bg-black/15 p-9 rounded-lg"></div> */}
                <Link
                  to="/courses"
                  class="bg-orange-400 py-3 md:py-6 md:text-2xl font-medium px-6 md:px-16 rounded-2xl mb-3"
                >
                  Explore now
                </Link>
                <div>
                  <h1 class="text-xl font-semibold text-center md:text-left">
                    Now
                  </h1>
                  <p class="text-black/50 font-semibold text-center md:text-left">
                  Discover our top-quality courses designed for learners of all levels. Start your learning journey with us and unlock your potential.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex justify-center max-w-screen-2xl m-auto pb-10">
          <div className="w-10/12  ">
            <div className="text-center my-6">
              <h1 className="text-3xl font-bold "> Our Tracks</h1>
              <p className="text-black/50">
                lorem Ipsum is simply dummy text of the printing
              </p>
            </div>

            <div className=" w-full flex  flex-col gap-5 items-center md:flex-row mdjustify-evenly">
              {courses &&
                courses.map((item) => {
                  return (
                    <div className="w-full">
                      <div className="bg-white p-4 pb-20 shadow-xl shadow-black/10 rounded-xl ">
                        <img src="/bjs.png" className="w-full rounded-xl" />
                        <div>
                          <div className="flex justify-between w-full">
                            <p className="text-black/40 tracking-normal font-semibold text-sm">
                              {item?.courseCode}
                            </p>
                            <p>stars</p>
                          </div>
                          <h1 className="text-xl font-semibold tracking-normal">
                            {item?.courseName}
                          </h1>
                          <h1 className="text-orange-700 font-bold text-xl">
                            Rs {item?.fee}
                          </h1>
                        </div>
                        <div className="flex items-center">
                          <span>{item?.description}</span>
                        </div>
                      </div>
                      <div className="flex justify-center -mt-6">
                        <Link
                          to={`/courses/${item?._id}`}
                          className="bg-orange-700 text-white px-10 py-3 rounded-full font-semibold"
                        >
                          View Course
                        </Link>
                      </div>
                    </div>
                  );
                })}

              {/* <div className="w-full">
                <div className="bg-white p-4 pb-20 shadow-xl shadow-black/10 rounded-xl ">
                  <img src="/" className="w-full rounded-xl" />
                  <div>
                    <div className="flex justify-between w-full">
                      <p className="text-black/40 tracking-normal font-semibold text-sm">
                        UI/UX Design
                      </p>
                      <p>stars</p>
                    </div>
                    <h1 className="text-xl font-semibold tracking-normal">
                      UI/UX for Beginner
                    </h1>
                    <h1 className="text-orange-700 font-bold text-xl">$98</h1>
                  </div>
                  <div className="flex items-center">
                    <span> 22hr 30min</span>
                    <span> 22hr 30min</span>
                    <span> 22hr 30min</span>
                  </div>
                </div>
                <div className="flex justify-center -mt-6">
                  <button className="bg-orange-700 text-white px-10 py-3 rounded-full font-semibold">
                    Join Course
                  </button>
                </div>
              </div>
              <div className="w-full">
                <div className="bg-white p-4 pb-20 shadow-xl shadow-black/10 rounded-xl ">
                  <img src="/" className="w-full rounded-xl" />
                  <div>
                    <div className="flex justify-between w-full">
                      <p className="text-black/40 tracking-normal font-semibold text-sm">
                        UI/UX Design
                      </p>
                      <p>stars</p>
                    </div>
                    <h1 className="text-xl font-semibold tracking-normal">
                      UI/UX for Beginner
                    </h1>
                    <h1 className="text-orange-700 font-bold text-xl">$98</h1>
                  </div>
                  <div className="flex items-center">
                    <span> 22hr 30min</span>
                    <span> 22hr 30min</span>
                    <span> 22hr 30min</span>
                  </div>
                </div>
                <div className="flex justify-center -mt-6">
                  <button className="bg-orange-700 text-white px-10 py-3 rounded-full font-semibold">
                    Join Course
                  </button>
                </div>
              </div> */}
            </div>
          </div>
        </div>


        <div className="flex justify-center items-center">
          <div className="p-5 bg-red-100 m-5 rounded-[1.5rem] relative custom-max-width w-full">
            <p className="lg:text-[48px] text-[20px] font-semibold text-l md:text-5xl my-3">
              Kickstart your career{" "}
            </p>
            <p className="lg:text-[48px] text-[20px] font-semibold text-l md:text-5xl my-3">
              <span className="text-[#FF7426]">Get certified </span>by
              completing the courses
            </p>
            <Link to='/assessments' className="bg-orange-400 py-3 md:py-4 md:text-2xl font-medium px-5 md:px-16 rounded-2xl mb-3 my-5 inline-block">
              Get Certified
            </Link>
            <img
              src="/Group2.png"
              alt=""
              class="absolute left-0 top-32 w-8 md:w-14"
            ></img>
          </div>
        </div>

        <div className="bg-white py-10">
          <div className="max-w-screen-2xl m-auto">
            <div className="my-10 text-center ">
              <h2 className="text-3xl font-bold">What student’s say</h2>
              <p className="text-md font-semibold text-black/50">
                Lorem Ipsum is simply dummy text of the printing.
              </p>
            </div>
            <div className="flex justify-between flex-col md:flex-row">
              <div className="p-6 shadow-md m-2 border">
                <q className="text-gray-500 font-medium">
                  “Teachings of the great explore of truth, the master-builder
                  of human happiness. no one rejects,dislikes, or avoids
                  pleasure itself, pleasure itself”
                </q>
                <div className="mt-5 flex items-center gap-3">
                  <img src="" className=" bg-black p-6 rounded-full  h-full" />
                  <div className="leading-normal">
                    <h1 className="font-semibold">Finlay Kirk</h1>
                    <p className="text-black/50">Web Developper</p>
                  </div>
                </div>
              </div>

              <div className="p-6 shadow-md m-2 border">
                <q className="text-gray-500 font-medium">
                  “Teachings of the great explore of truth, the master-builder
                  of human happiness. no one rejects,dislikes, or avoids
                  pleasure itself, pleasure itself”
                </q>
                <div className="mt-5 flex items-center gap-3">
                  <img src="" className=" bg-black p-6 rounded-full  h-full" />
                  <div className="leading-normal">
                    <h1 className="font-semibold">Finlay Kirk</h1>
                    <p className="text-black/50">Web Developper</p>
                  </div>
                </div>
              </div>

              <div className="p-6 shadow-md m-2 border">
                <q className="text-gray-500 font-medium">
                  “Teachings of the great explore of truth, the master-builder
                  of human happiness. no one rejects,dislikes, or avoids
                  pleasure itself, pleasure itself”
                </q>
                <div className="mt-5 flex items-center gap-3">
                  <img src="" className=" bg-black p-6 rounded-full  h-full" />
                  <div className="leading-normal">
                    <h1 className="font-semibold">Finlay Kirk</h1>
                    <p className="text-black/50">Web Developper</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="flex justify-center mt-10">
          <div className="w-10/12">
            <div className="flex justify-center">
              <div className="shadow-xl p-5 ">
                <img src="" className="h-52 bg-black rounded-lg" />
                <div className="mt-4 text-xl">
                  <h1 className="font-semibold text-xl ">Matthew E. McNatt</h1>
                  <p className="text-black/50 text-lg font-semibold">
                    {" "}
                    Professor @George Brown College
                  </p>
                  <p className="text-black/70 py-5 ">
                    Ut enim ad minim veniam, quis nost exercitation ullamco
                    laboris nisi ut allquip ex commodo.
                  </p>
                  <h1 className=" text-orange-500 font-medium">
                    Engineering physics
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
          <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
            <div>
              <p className="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-teal-900 uppercase rounded-full bg-teal-accent-400">
                Brand new
              </p>
            </div>
            <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
              <span className="relative inline-block">
                {/* <svg
                  viewBox="0 0 52 24"
                  fill="currentColor"
                  className="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-blue-gray-100 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block"
                >
                  <defs>
                    <pattern
                      id="bfcc89c7-3b4a-491a-bc7e-53e26502ff69"
                      x="0"
                      y="0"
                      width=".135"
                      height=".30"
                    >
                      <circle cx="1" cy="1" r=".7" />
                    </pattern>
                  </defs>
                  <rect
                    fill="url(#bfcc89c7-3b4a-491a-bc7e-53e26502ff69)"
                    width="52"
                    height="24"
                  />
                </svg> */}
                <span className="relative">The</span>
              </span>{" "}
              quick, brown fox jumps over a lazy dog
            </h2>
            <p className="text-base text-gray-700 md:text-lg">
              Sed ut perspiciatis unde omnis iste natus error sit voluptatem
              accusantium doloremque rem aperiam, eaque ipsa quae.
            </p>
          </div>
          <div className="grid gap-8 row-gap-8 lg:grid-cols-3">
            <div className="sm:text-center border bg-white p-5 rounded-lg hover:shadow">
              <div className="flex items-center justify-center w-24 h-24 mb-4 text-5xl font-extrabold rounded-full text-deep-purple-accent-400 bg-indigo-50 sm:mx-auto">
                1
              </div>
              <h6 className="mb-2 font-semibold leading-5">Slugging catcher</h6>
              <p className="max-w-md mb-3 text-sm text-gray-900 sm:mx-auto">
                Lookout flogging bilge rat main sheet bilge water nipper fluke
                to go on account heave down clap of thunder
              </p>
              <a
                href="/"
                aria-label=""
                className="inline-flex items-center font-semibold transition-colors duration-200 text-deep-purple-accent-400 hover:text-deep-purple-800"
              >
                Learn more
              </a>
            </div>
            <div className="sm:text-center border bg-white p-5 rounded-lg hover:shadow">
              <div className="flex items-center justify-center w-24 h-24 mb-4 text-5xl font-extrabold rounded-full text-deep-purple-accent-400 bg-indigo-50 sm:mx-auto">
                2
              </div>
              <h6 className="mb-2 font-semibold leading-5">Do your best</h6>
              <p className="max-w-md mb-3 text-sm text-gray-900 sm:mx-auto">
                They urge you to put down your sword and come join the winners.
                In 22 years the only 'winners'
              </p>
              <a
                href="/"
                aria-label=""
                className="inline-flex items-center font-semibold transition-colors duration-200 text-deep-purple-accent-400 hover:text-deep-purple-800"
              >
                Learn more
              </a>
            </div>
            <div className="sm:text-center border bg-white p-5 rounded-lg hover:shadow">
              <div className="flex items-center justify-center w-24 h-24 mb-4 text-5xl font-extrabold rounded-full text-deep-purple-accent-400 bg-indigo-50 sm:mx-auto">
                3
              </div>
              <h6 className="mb-2 font-semibold leading-5">Be yourself</h6>
              <p className="max-w-md mb-3 text-sm text-gray-900 sm:mx-auto">
                Tell them I hate them. Is the Space Pope reptilian!? Tell her
                she looks thin. Hello, little man
              </p>
              <a
                href="/"
                aria-label=""
                className="inline-flex items-center font-semibold transition-colors duration-200 text-deep-purple-accent-400 hover:text-deep-purple-800"
              >
                Learn more
              </a>
            </div>
          </div>
        </div>
        {/* <OurPartners/> */}

        <div className="p-2">
          <div className="custom-max-width m-auto">
            <div className="relative w-[100%] h-[250px] m-auto bg-cover bg-no-repeat bg-center  bg-[#302b27]  rounded-[20px] my-5">
              <h1 className="flex  items-center lg:text-[40px] text-[20px] justify-center  h-full font-bold text-[#fff]">
                <div className="homepageright w-full  flex flex-col items-center justify-center  h-full ">
                  <h1 className="lg:text-[48px] text-[20px] font-[700] text-[#fff]">
                    Welcome to Community Hub
                  </h1>
                  <p className="lg:text-[18px] text-[14px] text-[#fff]">
                    Connect, Discuss, and Create Communities
                  </p>
                  <div className="flex mt-5 ">
                    <Link
                      to="/register"
                      className="bg-transparent text-white py-2 px-3 rounded-md transition duration-300  font-[700]  text-[14px] lg:text-[16px] border border-[#fff] "
                    >
                      Sing Up
                    </Link>
                    <Link
                      to="/courses"
                      className="bg-[#fff] text-[#000] py-2 px-4 rounded-md mx-2 transition duration-300 font-[700] text-[14px] lg:text-[16px]"
                    >
                      Join Course
                    </Link>
                  </div>
                </div>
              </h1>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Home;
