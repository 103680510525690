import axios from "axios";
import React, { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { apiurl } from "../config/config";
import LoaderSpinner from "../components/loader";
import { toast } from "react-toastify";

const VerifyEmailAction = () => {
  const { userId, token } = useParams();
  const [loading, setLoading] = useState(false);
  const [mailVerify, setMailVerify] = useState(true);
  const [reVerifyMail, setReVerifyMail] = useState(false);
  const [successAlert, setSuccessAlert] = useState(false);
  const [sendMailAlert, setSendMailAlert] = useState(false);
  const [email, setEmail] = useState("");
  const navigate = useNavigate();

  const verifyEmail = async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        `${apiurl}/user/email-verify/${userId}/${token}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log("Verification response:", response.data);

      if (response?.data.success === true) {
        setLoading(false);
        setSuccessAlert(true);
        // navigate('/login');
        setReVerifyMail(false);
        setMailVerify(false);
      } else if (response?.data.success === false) {
        toast.error("Please re-verify the email");
        setLoading(false);
        setReVerifyMail(true);
        setMailVerify(false);
      } else {
        setMailVerify(true);
        setReVerifyMail(false);
        setLoading(false);
      }
    } catch (error) {
      console.error("Verifying email error:", error);
      setLoading(false);
      setMailVerify(true);
    }
  };

  const handleReVerifyMail = async () => {
    setLoading(true);
    if (email === "") {
      toast.error("Please fill in the email");
      setLoading(false);
      return;
    }
    try {
      const response = await axios.post(
        `${apiurl}/user/resend-verification-token`,
        { email }
      );
      console.log("resend-verification response >>>>>", response);

      if (response.status === 200) {
        setSendMailAlert(true);
        setLoading(false);
        setReVerifyMail(false);
      } else {
        toast.error("User not found please re-verify the email");
        setLoading(false);
      }
    } catch (error) {
      console.error("ReVerifyMail error", error);
      setLoading(false);
    }
  };

  return (
    <div>
      {loading ? <LoaderSpinner text="Verifying..." /> : null}
      <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 h-full overflow-y-scroll">
        <div className="xl:w-1/3 p-10 bg-white border border-1 rounded-xl relative">
          {mailVerify ? (
            <div className="text-center w-full mx-auto">
              <p className="text-lg w-11/12 mx-auto text-black">
                To proceed, please verify your email address. Once verified, you
                will be redirected to the login page.
              </p>
              <form className="space-y-4 md:space-y-5 w-full bg-white md:mt-0 sm:max-w-md sm:p-8 m-auto">
                <h2 className="mb-1 text-xl font-bold leading-tight tracking-tight text-[#005AE6] md:text-2xl text-center">
                  Verify your Email
                </h2>
                <button
                  type="button"
                  className="w-full text-white hover:text-[#005AE6] border border-[#005AE6] hover:bg-white bg-[#005AE6] font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                  onClick={verifyEmail}
                >
                  Verify
                </button>
              </form>
            </div>
          ) : (
            <>
              {mailVerify === true ? (
                <div className="xl:w-1/3 p-10 bg-white border border-1 rounded-xl relative">
                  <div class="text-center w-full mx-auto">
                    <div className="flex justify-center">
                      <img className="w-24" src="/done-icon.png" />
                    </div>
                    <p class="text-lg w-11/12 mx-auto my-3 text-black">
                      now you can login your account and start lerning
                    </p>
                    <form class="w-full bg-white  m-auto">
                      <h2 class="text-xl font-bold leading-tight tracking-tight text-[#005AE6] md:text-2xl text-center">
                        Email Verify Succesfully
                      </h2>
                      <Link
                        to="login"
                        class="inline-block w-full text-white hover:text-[#005AE6] border border-[#005AE6] hover:bg-white bg-[#005AE6] font-medium rounded-lg text-sm px-5 py-2.5 text-center mt-3"
                      >
                        Login
                      </Link>
                    </form>
                  </div>
                </div>
              ) : null}
            </>
          )}

          {reVerifyMail ? (
            <div className="text-center w-full mx-auto">
              <p className="text-lg w-11/12 mx-auto text-black">
                Enter your registered email address to verify it. Click the link
                in the email to finish the process and login.
              </p>
              <div className="max-w-md w-full p-6  dark:border mx-auto">
                <h2 className="mb-1 text-xl font-bold leading-tight tracking-tight text-[#005AE6] md:text-2xl text-center">
                  Reverify your Email
                </h2>
                <div>
                  <label
                    htmlFor="email"
                    className="block mb-2 text-left font-medium text-gray-900"
                  >
                    E-mail:
                  </label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    value={email}
                    placeholder="Email"
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    className="border border-gray-300 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:focus:ring-blue-500 dark:focus:border-blue-500 bg-[#EFF5FF]"
                  />
                </div>
                <button
                  type="submit"
                  className="w-full text-white hover:text-[#005AE6] border bg-[#005AE6] border-[#005AE6] hover:bg-white font-medium rounded-lg text-sm px-5 py-2.5 text-center mt-4"
                  onClick={handleReVerifyMail}
                >
                  Re-Verify
                </button>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default VerifyEmailAction;
