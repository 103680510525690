import React, { useState, useEffect } from "react";
import Certificate from "../components/certificate";
import { PDFViewer } from "@react-pdf/renderer";
import Invoice from "../components/invoice.js";
import Layout from "../components/layout.js";

const Test = () => {
  const content = `<!DOCTYPE html>
    <html lang="en">
    <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>What is HTML?</title>
        <style>
            /* Inline CSS styles */
            h1 {
                color: blue;
                font-size: 24px;
            }
    
            p {
                font-family: Arial, sans-serif;
                font-size: 16px;
                line-height: 1.5;
            }
    
            h2 {
                color: green;
                font-size: 20px;
            }
    
            ol {
                margin-left: 20px;
            }
    
            li {
                font-style: italic;
            }
    
            pre {
                background-color: #f0f0f0;
                padding: 10px;
                border-radius: 5px;
            }
        </style>
    </head>
    <body>
        <h1>What is HTML?</h1>
        <p>HTML (Hypertext Markup Language) is the standard markup language for creating web pages and web applications. It defines the structure of a web page by using a variety of tags and attributes.</p>
        <h2>Key Concepts:</h2>
        <ol>
            <li>Tags: HTML documents are made up of HTML elements, which are defined by tags. Tags are enclosed in angle brackets, such as &lt;html&gt;, &lt;head&gt;, &lt;title&gt;, &lt;body&gt;, &lt;p&gt;, &lt;div&gt;, &lt;a&gt;, etc.</li>
            <li>Attributes: Tags may contain attributes that provide additional information about the element. Attributes are added to the opening tag and are written as name-value pairs, such as class="container", id="main", href="https://example.com", etc.</li>
            <li>Structure: HTML documents have a hierarchical structure. They typically include a &lt;html&gt; element that contains &lt;head&gt; and &lt;body&gt; elements. The head section contains metadata and links to external resources, while the body section contains the content of the web page.</li>
            <li>Text Formatting: HTML provides various elements for text formatting, including headings (&lt;h1&gt; to &lt;h6&gt;), paragraphs (&lt;p&gt;), emphasis (&lt;em&gt; and &lt;strong&gt;), lists (&lt;ul&gt;, &lt;ol&gt;, and &lt;li&gt;), etc.</li>
            <li>Links and Images: HTML allows you to create hyperlinks (&lt;a&gt;) to other web pages or resources and embed images (&lt;img&gt;) into your documents.</li>
            <li>Semantic Markup: HTML5 introduced semantic elements such as &lt;header&gt;, &lt;footer&gt;, &lt;nav&gt;, &lt;article&gt;, &lt;section&gt;, &lt;aside&gt;, etc., which provide more meaningful structure to web pages and improve accessibility and SEO.</li>
        </ol>
        <h2>Example:</h2>
        <pre>
            &lt;!DOCTYPE html&gt;
            &lt;html lang="en"&gt;
            &lt;head&gt;
                &lt;meta charset="UTF-8"&gt;
                &lt;meta name="viewport" content="width=device-width, initial-scale=1.0"&gt;
                &lt;title&gt;Sample HTML Page&lt;/title&gt;
            &lt;/head&gt;
            &lt;body&gt;
                &lt;header&gt;
                    &lt;h1&gt;Welcome to My Website&lt;/h1&gt;
                    &lt;nav&gt;
                        &lt;ul&gt;
                            &lt;li&gt;&lt;a href="#"&gt;Home&lt;/a&gt;&lt;/li&gt;
                            &lt;li&gt;&lt;a href="#"&gt;About&lt;/a&gt;&lt;/li&gt;
                            &lt;li&gt;&lt;a href="#"&gt;Services&lt;/a&gt;&lt;/li&gt;
                            &lt;li&gt;&lt;a href="#"&gt;Contact&lt;/a&gt;&lt;/li&gt;
                        &lt;/ul&gt;
                    &lt;/nav&gt;
                &lt;/header&gt;
                
                &lt;section&gt;
                    &lt;h2&gt;About Us&lt;/h2&gt;
                    &lt;p&gt;Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eget magna at massa mollis hendrerit.&lt;/p&gt;
                &lt;/section&gt;
                
                &lt;section&gt;
                    &lt;h2&gt;Our Services&lt;/h2&gt;
                    &lt;ul&gt;
                        &lt;li&gt;Web Design&lt;/li&gt;
                        &lt;li&gt;Graphic Design&lt;/li&gt;
                        &lt;li&gt;SEO Optimization&lt;/li&gt;
                    &lt;/ul&gt;
                &lt;/section&gt;
                
                &lt;footer&gt;
                    &lt;p&gt;&amp;copy; 2024 MyWebsite. All rights reserved.&lt;/p&gt;
                &lt;/footer&gt;
            &lt;/body&gt;
            &lt;/html&gt;
        </pre>
        <p>This example demonstrates a basic HTML structure with a header, navigation, sections for content, and a footer. HTML allows you to create rich and interactive web pages by combining various elements and attributes.</p>
    </body>
    </html>
    `;

  return (
    <Layout>
<div className="flex-1 p-8 bg-white">
      <div className="mb-8 flex justify-between items-center">
        <h2 className="text-3xl font-bold">My Courses</h2>
        <button className="bg-blue-500 text-white px-4 py-2 rounded">Become Mentor</button>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-8">
        <CourseCard title="Basic of Mobile UX" studio="Simple Studio" rating="4.0" />
        <CourseCard title="Illustrator Course" studio="Simple Studio" rating="4.0" />
        <CourseCard title="Basics of UI Design" studio="Simple Studio" rating="4.0" />
      </div>
      <div className="flex justify-between items-center mb-8">
        <Progress />
        <div className="w-1/3">
          <img src="/path/to/banner.png" alt="Banner" />
        </div>
      </div>
      <div>
        <h3 className="text-xl font-bold mb-4">Continue Watching</h3>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className="bg-gray-200 p-4 rounded">
            <p className="text-gray-500">UX Design Course</p>
            <p className="text-gray-700">35 Min.</p>
          </div>
          <div className="bg-gray-200 p-4 rounded">
            <p className="text-gray-500">UX Design Course</p>
            <p className="text-gray-700">35 Min.</p>
          </div>
        </div>
      </div>
    </div>

      <div class="bg-gray-100 p-4 rounded-lg">
        <pre>
          <code class="text-sm text-gray-800">
            {/* <div dangerouslySetInnerHTML={{ __html: content }} /> */}
          </code>
        </pre>
      </div>

 <CourseDetail/>
 <CourseCard2/>




 <div className="flex-1 p-8">
      <header className="mb-8">
        <h1 className="text-4xl font-bold">Never stop learning</h1>
        <input 
          type="text"
          placeholder="Search for courses"
          className="mt-4 p-2 w-full border rounded"
        />
      </header>
      <section>
        <h2 className="text-2xl font-bold mb-4">Popular Courses</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          <CourseCard title="Web Development" instructor="Jack Mcdougals" rating="4.5" />
          <CourseCard title="Photography" instructor="Katherine Miller" rating="4.7" />
          <CourseCard title="Programming" instructor="Natalie Washington" rating="5.0" />
          <CourseCard title="Data Science - Python" instructor="Zachary Ross" rating="4.8" />
        </div>
      </section>
      <section className="mt-8">
        <h2 className="text-2xl font-bold mb-4">Popular Schools</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          <CourseCard title="Front-end Development" instructor="Frankie Turizo" rating="5.0" />
          <CourseCard title="Graphic Design For Social Media" instructor="Anthony Matts" rating="4.9" />
        </div>
      </section>
      <section className="mt-8">
        <h2 className="text-2xl font-bold mb-4">Recommended Blogs</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          <CourseCard title="Why Reading is Important" instructor="John Doe" rating="4.5" />
          <CourseCard title="How to Practice Mindfulness" instructor="Jane Smith" rating="4.7" />
        </div>
      </section>
    </div>



    </Layout>
  );
};

export default Test;


export function CourseCard({ title, studio, rating }) {
  return (
    <div className="bg-blue-50 p-4 rounded shadow">
      <h3 className="text-xl font-bold mb-2">{title}</h3>
      <p className="text-gray-500 mb-2">By {studio}</p>
      <p className="text-gray-400 text-sm">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Justo interdum molestie hac vulputate proin lorem.</p>
      <div className="mt-4 flex justify-between items-center">
        <button className="bg-blue-500 text-white px-4 py-2 rounded">View Course</button>
        <p className="text-yellow-500">{rating} ★</p>
      </div>
    </div>
  );
}

export function Progress() {
  return (
    <div className="bg-gray-100 p-4 rounded shadow w-2/3">
      <h3 className="text-lg font-bold mb-2">My Progress</h3>
      <p className="text-gray-500 mb-4">Machine Learning</p>
      <div className="flex items-center mb-4">
        <div className="flex-1 h-2 bg-gray-300 rounded-full overflow-hidden mr-4">
          <div className="h-full bg-blue-500" style={{ width: '25%' }}></div>
        </div>
        <span className="text-gray-500">35% Left</span>
      </div>
      <p className="text-gray-500 text-sm">Course 3, Week 2<br />May 7 - May 14</p>
    </div>
  );
}

export function CourseDetail() {
  return (
    <div className="w-1/3 bg-white p-8 border-l">
      <button className="mb-4">
        <img src="/path/to/close-icon.png" alt="Close" />
      </button>
      <img src="/path/to/course-detail-image.png" alt="Course Detail" className="rounded mb-4" />
      <h2 className="text-2xl font-bold mb-2">Programming and Software Development</h2>
      <p className="text-yellow-500 mb-4">5.0 ★</p>
      <p className="mb-4">
        Learn programming from scratch to professional for software engineering, data science, and computer science.
        Master the most demanded technology in the industry.
      </p>
      <h3 className="text-xl font-bold mb-2">Entry Level</h3>
      <ul className="list-disc pl-4">
        <li>Basic Computing Course</li>
        <li>Basic Algorithms and Logical Thinking Course</li>
        <li>Introduction to the Web: History and Operation of the Internet Fundamentals</li>
        <li>Fundamentals of Software Engineering</li>
        <li>Definitive Course of HTML and CSS</li>
      </ul>
    </div>
  );
}

export function CourseCard2({ title, instructor, rating }) {
  return (
    <div className="bg-white p-4 rounded-lg shadow">
      <img src="/path/to/course-image.png" alt={title} className="rounded mb-4" />
      <h3 className="text-lg font-bold mb-2">{title}</h3>
      <p className="text-gray-500 mb-2">{instructor}</p>
      <p className="text-yellow-500">{rating} ★</p>
    </div>
  );
}