import React from "react";
import { Link } from "react-router-dom";
import Layout from "../components/layout";

const Thankyou = ({ orderNumber }) => {
  return (
    <Layout>
    <div className="bg-gray-100 min-h-screen flex justify-center items-center">
      <div className="bg-white p-8 rounded-lg shadow-md text-center">
        <h2 className="text-3xl font-semibold mb-4">
          Thank You for Your Purchase!
        </h2>
        <p className="text-lg mb-4">Your order has been successfully placed.</p>
        <p className="text-gray-600">
          Order Number: <span className="font-semibold">{orderNumber}</span>
        </p>
        <Link
          to="/dashboard"
          className="bg-[#4D2C5E] hover:bg-[#FF7426] text-xl text-white font-semibold my-5 inline-block p-3 uppercase w-full rounded-md"
        >
          Go Dashboard
        </Link>
      </div>
    </div>
    </Layout>
  );
};

export default Thankyou;
