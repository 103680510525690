import React, { useState } from 'react';
import LoaderSpiner from '../components/loader';
import axios from 'axios';
import { apiurl } from '../config/config';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const AccountVerify = () => {

  const [email, setEmail] = useState('');
  // console.log("verify email data is >>>>>>>>>>>>", email)
  const [loading, setLoading] = useState(false)
  const [loginpopup, setLoginPopup] = useState(false)
  const navigate = useNavigate();

  const verifyEmail = async () => {
    try {
      setLoading(true);
      const response = await axios.post(`${apiurl}/user/resend-verification-token`, { email });;
      // console.log('Verification response:', response.data);

      if (response?.data.success === true) {
        setLoading(false);
        setLoginPopup(true)
        toast.success(`${response.data.message} Please check your Mail for the Email Re-Verification`)
        setEmail(" ")
      } else if (response?.data.success === false) {
        toast.error('Please re-verify the email');
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.error('Verifying email error:', error);
      setLoading(false);
    }
  };

  return (
    <div>
      {loading === true ? <LoaderSpiner text="Loading ..." /> : null}
      <div className="fixed inset-0 flex items-center justify-center bg-gray-200 bg-opacity-50 h-full overflow-y-scroll">
        <div className="xl:w-1/3 sm:w-1/2 pt-10 px-4 bg-white border border-1 rounded-xl relative m-4 shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px]">
          <div className="text-center w-full mx-auto">
            <div className="p-3 mx-5">
              <p className="text-xl font-semibold p-2 text-center">
                {loginpopup === false ?
                  "Please enter your registered email." :
                  "We have sent you a link to your email address. Please check on it for the email re-verification. Once verified, you will be redirected to the login page."
                }

              </p>
            </div>

            <div className="space-y-4 md:space-y-5 w-full md:mt-0 sm:max-w-md sm:p-8 m-auto mb-5 sm:pt-0">
              {loginpopup === false ?
                <div className="text-center relative flex justify-center items-center mb-3">
                  <input
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder='please enter registered mail'
                    className="text-sm text-[#005AE6] md:text-lg text-center border py-2 rounded-md focus:outline-none w-11/12"
                  />
                </div> : null}

              {loginpopup === true ?
                < button
                  type="button"
                  className="w-3/4 text-white border border-blue-800 hover:bg-blue-700 bg-[#005AE6] font-medium rounded-lg text-sm py-2.5 text-center"
                  onClick={() => navigate('/login')}
                >
                  Login
                </button> :
                < button
                  type="button"
                  className="w-3/4 text-white border border-blue-800 hover:bg-blue-700 bg-[#005AE6] font-medium rounded-lg text-sm py-2.5 text-center"
                  onClick={verifyEmail}
                >
                  Resend Verification Link
                </button>}
            </div>
          </div>
        </div>
      </div>
    </div >
  );
};

export default AccountVerify;
