// import React, { useRef } from 'react';
// import html2canvas from 'html2canvas'; // For converting to PNG
// import jsPDF from 'jspdf'; // For converting to PDF



// const Certificate = () => {
//   const componentRef = useRef(null);

//   const handleConvert = () => {
//     // Convert to PNG
//     html2canvas(componentRef.current).then(canvas => {
//       const imgData = canvas.toDataURL('image/png');
//       const pngImage = new Image();
//       pngImage.src = imgData;
//       document.body.appendChild(pngImage);
//     });

//     // Convert to PDF
//     const pdf = new jsPDF();
//     pdf.addHTML(componentRef.current, () => {
//       pdf.save('certificate.pdf');
//     });
//   };

//   return (
//     <div>
//       <div ref={componentRef}>
//         {/* Your certificate content goes here */}
//         <h1>Certificate of Achievement</h1>
//         <p>This is to certify that John Doe has successfully completed the course.</p>
//         {/* Add more content as needed */}
//       </div>
//       <button onClick={handleConvert}>Convert and Download</button>
//     </div>
//   );
// };

// export default Certificate;



// import React from 'react';
// import { Document, Page, Text, View, StyleSheet, PDFViewer } from '@react-pdf/renderer'; // For generating PDF

// const styles = StyleSheet.create({
//   page: {
//     flexDirection: 'row',
//     backgroundColor: '#E4E4E4',
//   },
//   section: {
//     margin: 10,
//     padding: 10,
//     flexGrow: 1,
//   },
// });

// const Certificate = () => {
//   const handleDownloadPDF = () => {
//     // Create PDF blob
//     const pdfBlob = (
//       <Document>
//         <Page size="A4" style={styles.page}>
//           <View style={styles.section}>
//             <Text>Certificate of Achievement</Text>
//             <Text>This is to certify that John Doe has successfully completed the course.</Text>
//             {/* Add more content as needed */}
//           </View>
//         </Page>
//       </Document>
//     ).toBlob();

//     // Download PDF
//     const link = document.createElement('a');
//     link.href = URL.createObjectURL(pdfBlob);
//     link.download = 'certificate.pdf';
//     link.click();
//   };

//   return (
//     <div>
//       <PDFViewer width="1000" height="600">
//         <Document>
//           <Page size="A4" style={styles.page}>
//             <View style={styles.section}>
//               <Text>Certificate of Achievement</Text>
//               <Text>This is to certify that John Doe has successfully completed the course.</Text>
//               {/* Add more content as needed */}
//             </View>
//           </Page>
//         </Document>
//       </PDFViewer>
//       <button onClick={handleDownloadPDF}>Download PDF</button>
//     </div>
//   );
// };

// export default Certificate;





// import React, { useState } from 'react';
// import html2canvas from 'html2canvas'; // For converting to PNG
// import jsPDF from 'jspdf'; // For converting to PDF

// const Certificate = () => {
//   const [userName, setUserName] = useState('');
//   const [userID, setUserID] = useState('');
//   const [generated, setGenerated] = useState(false);

//   const handleGenerateCertificate = () => {
//     // You can generate certificate content here
//     // For simplicity, I'll just set the name and ID
//     setUserName('John Doe');
//     setUserID('123456');
//     setGenerated(true);
//   };

//   const handleDownloadImage = () => {
//     html2canvas(document.getElementById('certificate')).then(canvas => {
//       const imgData = canvas.toDataURL('image/png');
//       const link = document.createElement('a');
//       link.href = imgData;
//       link.download = 'certificate.png';
//       link.click();
//     });
//   };

//   const handleDownloadPDF = () => {
//     html2canvas(document.getElementById('certificate')).then(canvas => {
//       const imgData = canvas.toDataURL('image/png');
//       const pdf = new jsPDF();
//       pdf.addImage(imgData, 'PNG', 0, 0);
//       pdf.save('certificate.pdf');
//     });
//   };

//   return (
//     <div>
//       {!generated ? (
//         <button onClick={handleGenerateCertificate}>Generate Certificate</button>
//       ) : (
//         <div id="certificate">
//           <h1>Certificate of Achievement</h1>
//           <p>Name: {userName}</p>
//           <p>ID: {userID}</p>
//           <button onClick={handleDownloadImage}>Download as Image</button>
//           <button onClick={handleDownloadPDF}>Download as PDF</button>
//         </div>
//       )}
//     </div>
//   );
// };

// export default Certificate;



// import React, { useState } from 'react';
// import html2canvas from 'html2canvas'; // For converting to PNG
// import jsPDF from 'jspdf'; // For converting to PDF
// import CertificateDesign from './certificateDesign.js'; // Import the certificate design component

// const Certificate = () => {
//   const [userName, setUserName] = useState('');
//   const [userID, setUserID] = useState('');
//   const [generated, setGenerated] = useState(false);

//   const handleGenerateCertificate = () => {
//     // You can generate certificate content here
//     // For simplicity, I'll just set the name and ID
//     setUserName('John Doe');
//     setUserID('123456');
//     setGenerated(true);
//   };

//   const handleDownloadImage = () => {
//     html2canvas(document.getElementById('certificate')).then(canvas => {
//       const imgData = canvas.toDataURL('image/png');
//       const link = document.createElement('a');
//       link.href = imgData;
//       link.download = 'certificate.png';
//       link.click();
//     });
//   };

//   const handleDownloadPDF = () => {
//     html2canvas(document.getElementById('certificate')).then(canvas => {
//       const imgData = canvas.toDataURL('image/png');
//       const pdf = new jsPDF();
//       pdf.addImage(imgData, 'PNG', 0, 0);
//       pdf.save('certificate.pdf');
//     });
//   };

//   return (
//     <div>
//       {!generated ? (
//         <button
//           className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4"
//           onClick={handleGenerateCertificate}
//         >
//           Generate Certificate
//         </button>
//       ) : (
//         <div id="certificate">
//           <CertificateDesign userName={userName} userID={userID} />
//           <div className="flex justify-center">
//             <button
//               className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-4"
//               onClick={handleDownloadImage}
//             >
//               Download as Image
//             </button>
//             <button
//               className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
//               onClick={handleDownloadPDF}
//             >
//               Download as PDF
//             </button>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default Certificate;



// import React, { useState } from 'react';
// import html2canvas from 'html2canvas'; // For converting to PNG
// import jsPDF from 'jspdf'; // For converting to PDF
// import CertificateDesign from './certificateDesign'; // Import the certificate design component

// const Certificate = () => {
//   const [userName, setUserName] = useState('');
//   const [userID, setUserID] = useState('');
//   const [generated, setGenerated] = useState(false);

//   const handleGenerateCertificate = () => {
//     // You can generate certificate content here
//     // For simplicity, I'll just set the name and ID
//     setUserName('John Doe');
//     setUserID('123456');
//     setGenerated(true);
//   };

//   const handleDownloadCertificateImage = () => {
//     html2canvas(document.getElementById('certificate')).then(canvas => {
//       const imgData = canvas.toDataURL('image/png');
//       const link = document.createElement('a');
//       link.href = imgData;
//       link.download = 'certificate.png';
//       link.click();
//     });
//   };

//   const handleDownloadCertificatePDF = () => {
//     html2canvas(document.getElementById('certificate')).then(canvas => {
//       const imgData = canvas.toDataURL('image/png');
//       const pdf = new jsPDF();
//       pdf.addImage(imgData, 'PNG', 0, 0);
//       pdf.save('certificate.pdf');
//     });
//   };

//   return (
//     <div>
//       {!generated ? (
//         <button
//           className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4"
//           onClick={handleGenerateCertificate}
//         >
//           Generate Certificate
//         </button>
//       ) : (
//         <div id="certificate">
//           <CertificateDesign userName={userName} userID={userID} />
//           <div className="flex justify-center">
//             <button
//               className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-4"
//               onClick={handleDownloadCertificateImage}
//             >
//               Download as Image
//             </button>
//             <button
//               className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
//               onClick={handleDownloadCertificatePDF}
//             >
//               Download as PDF
//             </button>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default Certificate;



// import React, { useState } from 'react';
// // import { Document, Page, PDFViewer, StyleSheet } from '@react-pdf/renderer';
// import { Page, Text, View, Document, StyleSheet,PDFViewer } from '@react-pdf/renderer'; // For generating PDF
// import CertificateDesign from './certificateDesign'; // Import the certificate design component

// const styles = StyleSheet.create({
//     page: {
//       flexDirection: 'row',
//       backgroundColor: '#E4E4E4'
//     },
//     section: {
//       margin: 10,
//       padding: 10,
//       flexGrow: 1
//     }
//   });

// const Certificate = () => {
//   const [userName, setUserName] = useState('');
//   const [userID, setUserID] = useState('');
//   const [generated, setGenerated] = useState(false);

//   const handleGenerateCertificate = () => {
//     // You can generate certificate content here
//     // For simplicity, I'll just set the name and ID
//     setUserName('John Doe');
//     setUserID('123456');
//     setGenerated(true);
//   };

//   const handleDownloadPDF = () => {
//     const pdfBlob = (
//       <Document>
//         <Page size="A4" style={styles.page}>
//       <View style={styles.section}>
//         <Text>Section #1</Text>
//       </View>
//       <View style={styles.section}>
//         <Text>Section #2</Text>
//       </View>
//     </Page>
//       </Document>
//     ).toBlob();

//     const link = document.createElement('a');
//     link.href = URL.createObjectURL(pdfBlob);
//     link.download = 'certificate.pdf';
//     link.click();
//   };

//   return (
//     <div>
//       {!generated ? (
//         <button
//           className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4"
//           onClick={handleGenerateCertificate}
//         >
//           Generate Certificate
//         </button>
//       ) : (
//         <div>
//           <PDFViewer width="800" height="600">
//             <Document>
//             <Page size="A4" style={styles.page}>
//       <View style={styles.section}>
//         <Text>Section #1</Text>
//       </View>
//       <View style={styles.section}>
//         <Text>Section #2</Text>
//       </View>
//     </Page>
//             </Document>
//           </PDFViewer>
//           <button
//             className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4"
//             onClick={handleDownloadPDF}
//           >
//             Download PDF
//           </button>
//         </div>
//       )}
//     </div>
//   );
// };

// export default Certificate;


// working
// import React, { useState } from 'react';
// import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer'; // For generating PDF
// import CertificateDesign from './certificateDesign'; // Import the certificate design component

// const styles = StyleSheet.create({
//   page: {
//     flexDirection: 'row',
//     backgroundColor: '#E4E4E4'
//   },
//   section: {
//     margin: 10,
//     padding: 10,
//     flexGrow: 1
//   }
// });

// const Certificate = () => {
//   const [userName, setUserName] = useState('');
//   const [userID, setUserID] = useState('');
//   const [generated, setGenerated] = useState(false);

//   const handleGenerateCertificate = () => {
//     // You can generate certificate content here
//     // For simplicity, I'll just set the name and ID
//     setUserName('John Doe');
//     setUserID('123456');
//     setGenerated(true);
//   };

//   const handleDownloadPDF = () => {
//     const blob = new Blob([<Document>
//       <Page size="A4" style={styles.page}>
//         <CertificateDesign userName={userName} userID={userID} />
//       </Page>
//     </Document>], { type: 'application/pdf' });
    
//     const link = document.createElement('a');
//     link.href = URL.createObjectURL(blob);
//     link.download = 'certificate.pdf';
//     link.click();
//   };

//   return (
//     <div>
//       {!generated ? (
//         <button
//           className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4"
//           onClick={handleGenerateCertificate}
//         >
//           Generate Certificate
//         </button>
//       ) : (
//         <div>
//           <CertificateDesign userName={userName} userID={userID} />
//           <button
//             className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4"
//             onClick={handleDownloadPDF}
//           >
//             Download PDF
//           </button>
//         </div>
//       )}
//     </div>
//   );
// };

// export default Certificate;


// workingn blank
// import React, { useState } from 'react';
// import { Document, Page, StyleSheet, PDFDownloadLink } from '@react-pdf/renderer';
// import CertificateDesign from './certificateDesign';

// const styles = StyleSheet.create({
//   page: {
//     flexDirection: 'row',
//     backgroundColor: '#E4E4E4',
//     padding: 20,
//   },
// });

// const Certificate = () => {
//   const [userName, setUserName] = useState('');
//   const [userID, setUserID] = useState('');
//   const [generated, setGenerated] = useState(false);

//   const handleGenerateCertificate = () => {
//     // Generate certificate content here
//     setUserName('John Doe');
//     setUserID('123456');
//     setGenerated(true);
//   };

//   return (
//     <div>
//       {!generated ? (
//         <button
//           className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4"
//           onClick={handleGenerateCertificate}
//         >
//           Generate Certificate
//         </button>
//       ) : (
//         <div>
//           <CertificateDesign userName={userName} userID={userID} />
//           <PDFDownloadLink
//             document={
//               <Document>
//                 <Page size="A4" style={styles.page}>
//                   <CertificateDesign userName={userName} userID={userID} />
//                 </Page>
//               </Document>
//             }
//             fileName="certificate.pdf"
//           >
//             {({ blob, url, loading, error }) =>
//               loading ? 'Loading document...' : 'Download PDF'
//             }
//           </PDFDownloadLink>
//         </div>
//       )}
//     </div>
//   );
// };

// export default Certificate;

// working
// import React, { useState } from 'react';
// import { Document, Page, StyleSheet, PDFDownloadLink, Text, View} from '@react-pdf/renderer';
// import CertificateDesign from './certificateDesign';

// const styles = StyleSheet.create({
//   page: {
//     flexDirection: 'row',
//     backgroundColor: '#E4E4E4',
//     padding: 20,
//   },
//   container: {
//     border: '1px solid #000',
//     padding: 20,
//     maxWidth: '80%',
//     marginHorizontal: 'auto',
//     marginTop: 50,
//   },
//   title: {
//     fontSize: 24,
//     fontWeight: 'bold',
//     marginBottom: 20,
//   },
//   logo: {
//     width: 100,
//     marginBottom: 20,
//   },
//   barcode: {
//     marginTop: 20,
//     alignSelf: 'center',
//   },
//   text: {
//     marginBottom: 10,
//   },
// });

// const Certificate = () => {
//   const [userName, setUserName] = useState('');
//   const [userID, setUserID] = useState('');
//   const [generated, setGenerated] = useState(false);

//   const handleGenerateCertificate = () => {
//     // Generate certificate content here
//     setUserName('John Doe');
//     setUserID('123456');
//     setGenerated(true);
//   };

//   return (
//     <div>
//       {!generated ? (
//         <button
//           className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4"
//           onClick={handleGenerateCertificate}
//         >
//           Generate Certificate
//         </button>
//       ) : (
//         <div>
//           <CertificateDesign userName={userName} userID={userID} />
//           <PDFDownloadLink
//             document={
//               <Document>
//                 <Page size="A4" style={styles.page}>
//                 <View style={styles.container}>
//       {/* <Image src="/path/to/logo.png" style={styles.logo} />
//       <Text style={styles.title}>Certificate of Completion</Text>
//       <Text style={styles.text}>Name: Shubham Sharma</Text>
//       <Text style={styles.text}>Issue Date: 20/12/1111</Text>
//       <Text style={styles.text}>Course Name: Introduction js</Text>
//       <View style={styles.barcode}>
//         {/* <Barcode value="1234567890" /> */}
     
//                   <CertificateDesign userName={userName} userID={userID} />
//                   </View>
//                 </Page>
//               </Document>
//             }
//             fileName="certificate.pdf"
//           >
//             {({ blob, url, loading, error }) =>
//               loading ? 'Loading document...' : 'Download PDF'
//             }
//           </PDFDownloadLink>
//         </div>
//       )}
//     </div>
//   );
// };

// export default Certificate;






// import React, { useRef, useState } from "react";
// import QRCode from "qrcode.react";

// const Certificate = ({ name, id }) => {
//   const canvasRef = useRef(null);
//   const [showCertificate, setShowCertificate] = useState(false);

//   const downloadCertificate = () => {
//     const canvas = canvasRef.current;
//     const url = canvas.toDataURL("image/png");
//     const a = document.createElement("a");
//     a.href = url;
//     a.download = "certificate.png";
//     a.click();
//   };

//   const drawCertificate = () => {
//     setShowCertificate(true);
//     const canvas = canvasRef.current;
//     const ctx = canvas.getContext("2d");
//     ctx.fillStyle = "#fff"; // Background color
//     ctx.fillRect(0, 0, canvas.width, canvas.height);

//     // Drawing certificate border
//     ctx.strokeStyle = "#000";
//     ctx.lineWidth = 5;
//     ctx.strokeRect(0, 0, canvas.width, canvas.height);

//     // Drawing name
//     ctx.fillStyle = "#000";
//     ctx.font = "30px Arial";
//     ctx.fillText(`Name: ${name}`, 50, 100);

//     // Drawing ID QR code
//     const qrSize = 100;
//     const qrX = canvas.width - qrSize - 50;
//     const qrY = 50;
//     const qrCanvas = document.querySelector("canvas");
//     ctx.drawImage(qrCanvas, qrX, qrY, qrSize, qrSize);
//   };

//   return (
//     <div>
//       <canvas
//         ref={canvasRef}
//         width={800}
//         height={600}
//         style={{ border: "1px solid black", display: showCertificate ? "block" : "none" }}
//       ></canvas>
//       {/* <QRCode value={id} /> */}
//       <button onClick={drawCertificate}>Show Certificate</button>
//       <button onClick={downloadCertificate}>Download Certificate</button>
//     </div>
//   );
// };

// export default Certificate;


// import React from "react";
// import Canvas from "./canvas.js";

// const App = () => {
//   return (
//     <div>
//       <Canvas
//         name="John Doe"
//         id="123456789"
//         courseName="React.js Fundamentals"
//         enrollmentDate="2024-04-01"
//         issueDate="2024-05-09"
//         duration="4 weeks"
//       />
//     </div>
//   );
// };

// export default App;

// import React from 'react';

// const Certificate = ({ name, date, certId, issuedDate }) => {
//   return (
//     <div className="border-2 border-yellow-600 p-8 max-w-4xl mx-auto mt-10 bg-white shadow-md">
//       <div className="flex justify-between items-center mb-4">
//         <div>
//           <img src="https://via.placeholder.com/150" alt="Logo" className="h-16" />
//         </div>
//         <div className="text-center">
//           <h1 className="text-xl font-bold">Department of Science & Technology (Govt. of India) and IIT Roorkee</h1>
//           <p className="text-lg font-semibold">A joint initiative</p>
//         </div>
//         <div>
//           <img src="https://via.placeholder.com/150" alt="Logo" className="h-16" />
//         </div>
//       </div>

//       <h2 className="text-3xl font-bold text-center text-blue-900 mb-6">CERTIFICATE OF COMPLETION</h2>

//       <p className="text-xl text-center mb-2">This is to certify that</p>
//       <h3 className="text-3xl font-bold text-center mb-4">{name}</h3>
//       <p className="text-center mb-8">
//         has successfully completed <span className="font-bold">Executive Post Graduate Certification in Data Science and Artificial Intelligence</span> conducted by iHUB DivyaSampark at Indian Institute of Technology Roorkee in association with Department of Science & Technology from {date}.
//       </p>

//       <div className="grid grid-cols-3 gap-4 text-center">
//         <div>
//           <p className="font-bold">Manish Anand</p>
//           <p>CEO & Board Member</p>
//           <p>iHub DivyaSampark, IIT Roorkee</p>
//           <p className="text-sm">Issued Date: {issuedDate}</p>
//         </div>
//         <div>
//           <p className="font-bold">Dr. Sarath Menon</p>
//           <p>Course Coordinator, iHUB DivyaSampark,</p>
//           <p>IIT Roorkee</p>
//           <p className="text-sm">Certification can be verified at: xxxxx</p>
//         </div>
//         <div>
//           <p className="font-bold">Diwakar Chittora</p>
//           <p>CEO, Intellipaat</p>
//           <p className="text-sm">Certificate ID: {certId}</p>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Certificate;


// import React from 'react';

// const Certificate = ({ name, id, date, partners }) => {
//   return (
//     <div className="border-4 border-gray-300 p-8 max-w-3xl mx-auto mt-10 bg-white shadow-md relative">
//       <div className="absolute inset-0 opacity-10 flex items-center justify-center">
//         <p className="text-7xl font-bold text-gray-300">CREATIVE IT INSTITUTE</p>
//       </div>

//       <div className="flex justify-between items-center mb-4 relative z-10">
//         <img src="path/to/main-logo.png" alt="Main Logo" className="h-16" />
//         <div className="text-center">
//           <h1 className="text-2xl font-bold">CREATIVE IT INSTITUTE</h1>
//         </div>
//         <img src="path/to/main-logo.png" alt="Main Logo" className="h-16" />
//       </div>

//       <h2 className="text-3xl font-bold text-center text-red-500 mb-6 relative z-10">CERTIFICATE OF TRAINING</h2>

//       <div className="text-center mb-8 relative z-10">
//         <p className="text-lg">Presented to</p>
//         <h3 className="text-3xl font-bold">{name}</h3>
//         <p className="mt-2">Son of Md. Shamsul Islam & Mst. Shamsunnaher has successfully completed the</p>
//         <p className="font-bold">Professional UX/UI Design course</p>
//         <p>held on 25 May 2022 to 13 December 2022 at</p>
//         <p className="font-bold">Creative IT Institute</p>
//       </div>

//       <div className="flex justify-between items-center text-center relative z-10">
//         <div>
//           <p>ID No:</p>
//           <p className="font-bold">{id}</p>
//         </div>
//         <div>
//           <p>Date of Issue:</p>
//           <p className="font-bold">{date}</p>
//         </div>
//         <div>
//           <p className="text-sm">MOMTAZ PLAZA (4TH FLOOR), HOUSE # 07, ROAD # 04, DHANMONDI, DHAKA-1205, BANGLADESH</p>
//         </div>
//       </div>

//       <div className="flex justify-center mt-8 relative z-10">
//         <div className="text-center">
//           <p className="font-bold">Authorised Signature</p>
//         </div>
//       </div>

//       <div className="flex justify-around items-center mt-8 relative z-10">
//         <div className="text-center">
//           <img src="path/to/partner1-logo.png" alt="Partner 1 Logo" className="h-16" />
//           <p className="text-sm mt-2">Partner 1 Name</p>
//         </div>
//         <div className="text-center">
//           <img src="path/to/partner2-logo.png" alt="Partner 2 Logo" className="h-16" />
//           <p className="text-sm mt-2">Partner 2 Name</p>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Certificate;


// import React from 'react';
// import QRCode from 'qrcode.react';

// const Certificate = ({ user, course, certId, issueDate, partners }) => {
//   return (
//     <div className="border-4 border-gray-300 p-8 max-w-4xl mx-auto mt-10 bg-white shadow-md relative">
//       <div className="absolute inset-0 opacity-10 flex items-center justify-center">
//         <p className="text-7xl font-bold text-gray-300">ORGANIZATION NAME</p>
//       </div>

//       <div className="flex justify-between items-center mb-4 relative z-10">
//         <img src="path/to/org-logo.png" alt="Organization Logo" className="h-16" />
//         <div className="text-center">
//           <h1 className="text-2xl font-bold">ORGANIZATION NAME</h1>
//           <p className="text-lg font-semibold">Professional Certificate</p>
//         </div>
//         <img src="path/to/org-logo.png" alt="Organization Logo" className="h-16" />
//       </div>

//       <h2 className="text-3xl font-bold text-center text-blue-900 mb-6 relative z-10">CERTIFICATE OF COMPLETION</h2>

//       <div className="text-center mb-8 relative z-10">
//         <p className="text-lg">Presented to</p>
//         <h3 className="text-3xl font-bold">{user.name}</h3>
//         <p className="mt-2">for successfully completing the</p>
//         <p className="font-bold">{course}</p>
//         <p>course on {issueDate}.</p>
//       </div>

//       <div className="flex justify-between items-center text-center mb-8 relative z-10">
//         <div>
//           <p>ID No:</p>
//           <p className="font-bold">{certId}</p>
//         </div>
//         <div>
//           <p>Date of Issue:</p>
//           <p className="font-bold">{issueDate}</p>
//         </div>
//         <div>
//           <QRCode value={`https://your-verification-url.com/certificate/${certId}`} size={100} />
//         </div>
//       </div>

//       <div className="flex justify-center relative z-10">
//         <div className="text-center">
//           <img src="path/to/authorized-signature.png" alt="Authorized Signature" className="h-16" />
//           <p className="font-bold">Authorized Signature</p>
//         </div>
//       </div>

//       <div className="flex justify-around items-center mt-8 relative z-10">
//         {partners.map((partner, index) => (
//           <div className="text-center" key={index}>
//             <img src={partner.logo} alt={partner.name} className="h-16" />
//             <p className="text-sm mt-2">{partner.name}</p>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default Certificate;



import React from 'react';
import QRCode from 'qrcode.react';

const Certificate = ({ user, course, certId, issueDate, partners }) => {
  return (
    <div className="border-4 border-gray-300 p-8 max-w-4xl mx-auto bg-white shadow-md relative overflow-hidden">
      {/* Border Design */}
      <div className="absolute inset-0 border border-gray-300 pointer-events-none"></div>
      <div className="absolute top-0 left-0 w-full h-2 border-t-4 border-yellow-400 pointer-events-none"></div>
      <div className="absolute bottom-0 left-0 w-full h-2 border-b-4 border-yellow-400 pointer-events-none"></div>
      <div className="absolute top-0 left-0 w-2 h-full border-l-4 border-yellow-400 pointer-events-none"></div>
      <div className="absolute top-0 right-0 w-2 h-full border-r-4 border-yellow-400 pointer-events-none"></div>

      {/* Watermark */}
      <div className="absolute inset-0 flex items-center justify-center opacity-10">
        <img src="/logo.png" alt="Watermark Logo" className="w-1/3 h-1/3" />
      </div>

      <div className="flex justify-between items-center mb-4 relative z-10">
        <img src="path/to/org-logo.png" alt="Organization Logo" className="h-16" />
        <div className="text-center">
          <h1 className="text-2xl font-bold">ORGANIZATION NAME</h1>
          <p className="text-lg font-semibold">Professional Certificate</p>
        </div>
        <img src="path/to/org-logo.png" alt="Organization Logo" className="h-16" />
      </div>

      <h2 className="text-3xl font-bold text-center text-blue-900 mb-6 relative z-10">CERTIFICATE OF COMPLETION</h2>

      <div className="text-center mb-8 relative z-10">
        <p className="text-lg">Presented to</p>
        <h3 className="text-3xl font-bold">{user.name}</h3>
        <p className="mt-2">for successfully completing the</p>
        <p className="font-bold">{course}</p>
        <p>course on {issueDate}.</p>
      </div>

      <div className="flex justify-between items-center text-center mb-8 relative z-10">
        <div>
          <p>ID No:</p>
          <p className="font-bold">{certId}</p>
        </div>
        <div>
          <p>Date of Issue:</p>
          <p className="font-bold">{issueDate}</p>
        </div>
        <div>
          <QRCode value={`https://your-verification-url.com/certificate/${certId}`} size={100} />
        </div>
      </div>

      <div className="flex justify-center relative z-10">
        <div className="text-center">
          <img src="path/to/authorized-signature.png" alt="Authorized Signature" className="h-16" />
          <p className="font-bold">Authorized Signature</p>
        </div>
      </div>

      <div className="flex justify-around items-center mt-8 relative z-10">
        {partners.map((partner, index) => (
          <div className="text-center" key={index}>
            <img src={partner.logo} alt={partner.name} className="h-16" />
            <p className="text-sm mt-2">{partner.name}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Certificate;






