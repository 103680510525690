import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import Layout from "../components/layout";
import { apiurl } from "../config/config";

const Quiz = () => {
  const [quizCode, setQuizCode] = useState("");
  const [selectedOption, setSelectedOption] = useState("");

  const handleQuizCodeChange = (e) => {
    setQuizCode(e.target.value);
  };

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };

  // const [isConsoleOpen, setIsConsoleOpen] = useState(false);

  // useEffect(() => {
  //   // Function to check if the console is opened
  //   const checkConsoleOpen = () => {
  //     const threshold = 160;
  //     if (window.outerWidth - window.innerWidth > threshold || window.outerHeight - window.innerHeight > threshold) {
  //       if (!isConsoleOpen) {
  //         setIsConsoleOpen(true);
  //         alert('Console is opened!');
  //       }
  //     } else {
  //       if (isConsoleOpen) {
  //         setIsConsoleOpen(false);
  //       }
  //     }
  //   };

  //   // Check for console open periodically
  //   const intervalId = setInterval(checkConsoleOpen, 1000);

  //   // Clean up interval on component unmount
  //   return () => clearInterval(intervalId);
  // }, [isConsoleOpen]);

  // useEffect(() => {
  //   // Function to handle visibility change
  //   const handleVisibilityChange = () => {
  //     if (document.hidden) {
  //       alert('Tab visibility changed!');
  //     }
  //   };

  //   // Add event listener for visibility change
  //   document.addEventListener('visibilitychange', handleVisibilityChange);

  //   // Clean up event listener on component unmount
  //   return () => document.removeEventListener('visibilitychange', handleVisibilityChange);
  // }, []);

  const { courseId } = useParams();
  const navigate = useNavigate();
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [result, setResult] = useState(null);

  useEffect(() => {
    axios
      .get(`${apiurl}/quiz/all`)
      .then((response) => {
        setQuestions(response.data);
      })
      .catch((error) => {
        console.error("There was an error fetching the quiz!", error);
      });
  }, []);

  const handleSubmit = () => {
    axios
      .post(`${apiurl}/quiz/submit`, { answers })
      .then((response) => {
        setResult(response.data);
        setSubmitted(true);
      })
      .catch((error) => {
        console.error("There was an error submitting the quiz!", error);
      });
  };

  const handleAnswerChange = (questionId, selectedOption) => {
    const newAnswers = [...answers];
    const answerIndex = newAnswers.findIndex(
      (ans) => ans.questionId === questionId
    );
    if (answerIndex > -1) {
      newAnswers[answerIndex].selectedOption = selectedOption;
    } else {
      newAnswers.push({ questionId, selectedOption });
    }
    setAnswers(newAnswers);
  };

  if (!questions?.questions?.length) return <div>Loading...</div>;

  return (
    // <Layout>
    <div className="container mx-auto px-4 py-6">
      <h1 className="text-2xl font-bold mb-4">Quiz</h1>
      {submitted ? (
        <div>
          <h2 className="text-xl">Result</h2>
          <p>
            Score: {result.score} / {result.totalQuestions}
          </p>
          <p>Percentage: {result.percentage.toFixed(2)}%</p>
          {result?.resultDetails.map((detail) => (
            <div
              key={detail.questionId}
              className={detail.isCorrect ? "text-green-500" : "text-red-500"}
            >
              <p>{detail.question}</p>
              <p>
                Your Answer:{" "}
                {detail.selectedOption !== null
                  ? detail.selectedOption + 1
                  : "Not Answered"}
              </p>
              <p>Correct Answer: {detail.correctOption + 1}</p>
            </div>
          ))}
          <button
            onClick={() => navigate("/")}
            className="mt-4 bg-blue-500 text-white px-4 py-2 rounded"
          >
            Go back to quizzes
          </button>
        </div>
      ) : (
        <div>
          {questions?.questions.map((question, index) => (
            <div key={question._id} className="mb-4">
              <p>
                {index + 1}. {question.question}
              </p>
              {question.options.map((option, i) => (
                <div key={i}>
                  <input
                    type="radio"
                    name={`question-${question._id}`}
                    value={i}
                    onChange={() => handleAnswerChange(question._id, i)}
                  />
                  <label className="ml-2">{option}</label>
                </div>
              ))}
            </div>
          ))}
          <button
            onClick={handleSubmit}
            className="mt-4 bg-blue-500 text-white px-4 py-2 rounded"
          >
            Submit
          </button>
        </div>
      )}

      <div className="flex flex-col md:flex-row min-h-screen">
        {/* Left Panel */}
        <div className="bg-purple-100 p-6 md:w-1/2 flex flex-col items-center">
          <div className="w-full max-w-xs">
            <h2 className="text-2xl font-bold text-purple-700 mb-4">
              Let's play
            </h2>
            <p className="text-gray-600 mb-4">And be the first!</p>
            <div className="mb-6">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="quizCode"
              >
                Enter your quiz code
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="quizCode"
                type="text"
                placeholder="Ex: cS09S"
                value={quizCode}
                onChange={handleQuizCodeChange}
              />
              <button className="mt-4 bg-purple-700 hover:bg-purple-900 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
                Enter
              </button>
            </div>
            <div>
              <h3 className="text-lg font-bold text-gray-700 mb-2">
                Recent Quiz
              </h3>
              <ul>
                <li className="mb-2">
                  <button className="bg-white shadow-md hover:bg-gray-100 text-gray-700 font-semibold py-2 px-4 rounded inline-flex items-center w-full">
                    <span>Mathematic 2</span>
                    <span className="ml-auto text-gray-500">
                      10/10 Questions
                    </span>
                  </button>
                </li>
                <li className="mb-2">
                  <button className="bg-white shadow-md hover:bg-gray-100 text-gray-700 font-semibold py-2 px-4 rounded inline-flex items-center w-full">
                    <span>English for beginner</span>
                    <span className="ml-auto text-gray-500">
                      15/15 Questions
                    </span>
                  </button>
                </li>
              </ul>
            </div>
            <div className="mt-6">
              <h3 className="text-lg font-bold text-gray-700 mb-2">
                Live quiz
              </h3>
              <button className="bg-white shadow-md hover:bg-gray-100 text-gray-700 font-semibold py-2 px-4 rounded inline-flex items-center w-full">
                <span>English for work</span>
                <span className="ml-auto text-gray-500">1253 User playing</span>
              </button>
            </div>
          </div>
        </div>

        {/* Right Panel */}
        <div className="bg-white p-6 md:w-1/2 flex flex-col items-center">
          <div className="w-full max-w-xs">
            <h2 className="text-xl font-bold text-gray-800 mb-4">
              English for beginner
            </h2>
            <div className="mb-4">
              <p className="text-gray-700">Question 6/15</p>
              <p className="text-gray-900 font-semibold">
                Motor racing is the ---- sport in the world.
              </p>
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                <input
                  type="radio"
                  name="option"
                  value="Most expensive"
                  checked={selectedOption === "Most expensive"}
                  onChange={handleOptionChange}
                  className="mr-2"
                />
                Most expensive
              </label>
              <label className="block text-gray-700 text-sm font-bold mb-2">
                <input
                  type="radio"
                  name="option"
                  value="More expensive"
                  checked={selectedOption === "More expensive"}
                  onChange={handleOptionChange}
                  className="mr-2"
                />
                More expensive
              </label>
              <label className="block text-gray-700 text-sm font-bold mb-2">
                <input
                  type="radio"
                  name="option"
                  value="Expensivest"
                  checked={selectedOption === "Expensivest"}
                  onChange={handleOptionChange}
                  className="mr-2"
                />
                Expensivest
              </label>
              <label className="block text-gray-700 text-sm font-bold mb-2">
                <input
                  type="radio"
                  name="option"
                  value="As expensive"
                  checked={selectedOption === "As expensive"}
                  onChange={handleOptionChange}
                  className="mr-2"
                />
                As expensive
              </label>
            </div>
            <button className="bg-purple-700 hover:bg-purple-900 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
              Next
            </button>
          </div>
        </div>
      </div>

      <AddQuiz />

      <h3 class="mb-5 text-lg font-medium">
        How much do you expect to use each month?
      </h3>
      <ul class="grid w-full gap-6 md:grid-cols-2">
        <li>
          <input
            type="radio"
            id="hosting-small"
            name="hosting"
            value="hosting-small"
            class="hidden peer"
            required
          />
          <label
            for="hosting-small"
            class="inline-flex items-center justify-between w-full p-5 text-gray-500 bg-white border border-gray-200 hover:border-blue-600 rounded-lg cursor-pointer peer-checked:border-blue-600 peer-checked:bg-blue-600 peer-checked:text-white hover:text-blue-600 hover:bg-gray-100"
          >
            <div class="block">
              <div class="w-full text-lg font-semibold">0-50 MB</div>
              <div class="w-full">Good for small websites</div>
            </div>
          </label>
        </li>
        <li>
          <input
            type="radio"
            id="hosting-large"
            name="hosting"
            value="hosting-large"
            class="hidden peer"
            required
          />
          <label
            for="hosting-large"
            class="inline-flex items-center justify-between w-full p-5 text-gray-500 bg-white border border-gray-200 hover:border-blue-600 rounded-lg cursor-pointer peer-checked:border-blue-600 peer-checked:bg-blue-600 peer-checked:text-white hover:text-blue-600 hover:bg-gray-100"
          >
            <div class="block">
              <div class="w-full text-lg font-semibold">0-50 MB</div>
              <div class="w-full">Good for large websites</div>
            </div>
          </label>
        </li>
        <li>
          <input
            type="radio"
            id="hosting-extralarge"
            name="hosting"
            value="hosting-extralarge"
            class="hidden peer"
            required
          />
          <label
            for="hosting-extralarge"
            class="inline-flex items-center justify-between w-full p-5 text-gray-500 bg-white border border-gray-200 hover:border-blue-600 rounded-lg cursor-pointer peer-checked:border-blue-600 peer-checked:bg-blue-600 peer-checked:text-white hover:text-blue-600 hover:bg-gray-100"
          >
            <div class="block">
              <div class="w-full text-lg font-semibold">0-50 MB</div>
              <div class="w-full">Good for extralarge websites</div>
            </div>
          </label>
        </li>
        <li>
          <input
            type="radio"
            id="hosting-xxl"
            name="hosting"
            value="hosting-xxl"
            class="hidden peer"
            required
          />
          <label
            for="hosting-xxl"
            class="inline-flex items-center justify-between w-full p-5 text-gray-500 bg-white border border-gray-200 hover:border-blue-600 rounded-lg cursor-pointer peer-checked:border-blue-600 peer-checked:bg-blue-600 peer-checked:text-white hover:text-blue-600 hover:bg-gray-100"
          >
            <div class="block">
              <div class="w-full text-lg font-semibold">0-50 MB</div>
              <div class="w-full">Good for xxl websites</div>
            </div>
          </label>
        </li>
        {/* <li>
          <input
            type="radio"
            id="hosting-big"
            name="hosting"
            value="hosting-big"
            class="hidden peer"
          />
          <label
            for="hosting-big"
            class="inline-flex items-center justify-between w-full p-5 text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:peer-checked:text-blue-500 peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-600 hover:bg-gray-100 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700"
          >
            <div class="block">
              <div class="w-full text-lg font-semibold">500-1000 MB</div>
              <div class="w-full">Good for large websites</div>
            </div>
            <svg
              class="w-5 h-5 ms-3 rtl:rotate-180"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 10"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M1 5h12m0 0L9 1m4 4L9 9"
              />
            </svg>
          </label>
        </li> */}
      </ul>



<div className="flex justify-center items-center">


<div class="flex items-center ps-4 border border-gray-200 rounded dark:border-gray-700">
    <input id="bordered-radio-1" type="radio" value="" name="bordered-radio" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
    <label for="bordered-radio-1" class="w-full py-4 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Default radio</label>
</div>
<div class="flex items-center ps-4 border border-gray-200 rounded dark:border-gray-700">
    <input checked id="bordered-radio-2" type="radio" value="" name="bordered-radio" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
    <label for="bordered-radio-2" class="w-full py-4 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Checked state</label>
</div>
</div>

    </div>
    //</Layout>
  );
};

export default Quiz;

export const AddQuiz = () => {
  const [courseList, setCourseList] = useState([]);
  const [question, setQuestion] = useState("");
  const [courseId, setCourseId] = useState("");
  const [status, setStatus] = useState("active");
  const [options, setOptions] = useState(["", "", "", ""]);
  const [correctOption, setCorrectOption] = useState(0);

  const handleOptionChange = (index, value) => {
    const newOptions = [...options];
    newOptions[index] = value;
    setOptions(newOptions);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newQuiz = { question, courseId, status, correctOption, options };
    try {
      const response = await axios.post(
        "http://localhost:8080/quiz/add",
        newQuiz
      );
      alert("Quiz added successfully!");
      // Reset form fields
      setQuestion("");
      setCourseId("");
      setStatus("active");
      setOptions(["", "", "", ""]);
      setCorrectOption(0);
    } catch (error) {
      console.error("Error adding quiz:", error);
      alert("Failed to add quiz.");
    }
  };

  const fetchCourses = async (e) => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: "http://localhost:8080/course/all",
    };

    axios
      .request(config)
      .then((response) => {
        console.log(JSON.stringify(response?.data));
        setCourseList(response?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchCourses();
  }, []);

  return (
    <div>
      <h1>Add New Quiz</h1>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Course ID:</label>
          <span>{courseId}</span>
        </div>

        <div>
          <label>Course List:</label>
          <select
            value={courseId}
            onChange={(e) => setCourseId(e.target.value)}
            required
          >
            <option value={null}>Select Course</option>
            {courseList?.map((course, index) => (
              <option key={index} value={course?._id}>
                {course?.courseName}
              </option>
            ))}
          </select>
        </div>

        <div>
          <label>Question:</label>
          <input
            type="text"
            value={question}
            onChange={(e) => setQuestion(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Options:</label>
          {options.map((option, index) => (
            <div className="m-2 p-1 border" key={index}>
              <span className="mx-2 px-2 bg-red-600">{index + 1}</span>
              <input
                type="text"
                value={option}
                onChange={(e) => handleOptionChange(index, e.target.value)}
                required
              />
            </div>
          ))}
        </div>
        <div>
          <label>Correct Option:</label>
          <select
            value={correctOption}
            onChange={(e) => setCorrectOption(parseInt(e.target.value))}
            required
          >
            {options.map((option, index) => (
              <option key={index} value={index}>
                {option}
              </option>
            ))}
          </select>
        </div>
        <button type="submit">Add Quiz</button>
      </form>
    </div>
  );
};
