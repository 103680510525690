import React, { useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
// import Profile from "../pages/profile";
// import EditProfile from '../pages/EditProfile';
import About from "../pages/about";
// import Group from "../pages/group";
// import Discussion from "../pages/discussion";
// import DiscussionOverview from "../pages/discussionOverview";
import Login from "../pages/login";
// import ChatBox from "../pages/chat";
import NotFound from "../pages/notFound";
import Unauthorized from "../pages/unauthorized";
import Home from "../pages/home.js";
import Register from "../pages/register.js";
import ProtectedRoute from "../config/ProtectedRoute.js";
// import CreateCommunities from "../pages/createcommunities.js"
import ForgotPass from "../pages/forgotPassword.js";
import VerifyEmail from "../pages/verifyEmail.js";
// import AcceptCommunity from "../components/acceptcommunity.js";
// import Products from "../components/products.js";
// import UserProfile from "../pages/userProfile.js";
import AccountVerify from "../pages/accountVerify.js";
import Setting from "../pages/setting.js";
import Dashboard from "../pages/dashboard.js";
import Courses from "../pages/courses.js";
import Contact from "../pages/contact.js";
import Plus from "../pages/plus.js";
import Profile from "../pages/profile.js";
import Course from "../pages/course.js";
import Services from "../pages/services.js";
import CartPage from "../pages/cart.js";
import HelpCenter from "../pages/help-center.js";
import Orders from "../pages/orders.js";
import Thankyou from "../pages/thankyou.js";
import Teams from "../pages/teams.js";
import Test from "../pages/test.js";
import Topic from "../pages/topic.js";
import ErrorPage from "../pages/errorPage.js";
import Quiz from "../pages/quiz.js";
import Assessments from "../pages/assessments.js";
import VerifyEmailAction from "../pages/verifyEmailAction.js";
import VerifyEmailSendLink from "../pages/verifyEmailSendLink.js";
// import MemberList from "../components/memberList.js";
// import VendorRegister from "../pages/vendorRegister.js";
// import AcceptInviteCommunity from "../components/acceptInviteCommunity.js";
const RoutePage = () => {
  const [communityData, setCommunityData] = useState([])


  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/profile" element={<Profile />} />
        {/* <Route path="/forgot-password" element={<ForgotPass />} /> */}
        <Route path="*" element={<NotFound />} />
        <Route path="/unauthorized" element={<Unauthorized />} />
        {/* <Route path="/dashboard" element={<Dashboard />} /> */}
        <Route path="/courses" element={<Courses />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/plus" element={<Plus />} />
        <Route path="/about" element={<About />} />
        <Route path="/courses/:courseid" element={<Course />} />
        <Route path="/services" element={<Services />} />
        {/* <Route path="/cart" element={<CartPage />} /> */}
        <Route path="/help-center" element={<HelpCenter />} />
        <Route path="/orders" element={<Orders />} />
        <Route path="/thankyou" element={<Thankyou />} />
        <Route path="/teams" element={<Teams />} />
        <Route path="/course/test/topic" element={<Topic />} />
        <Route path="/error" element={<ErrorPage />} />
        <Route path="/test" element={<Test />} />
        <Route path="/assessment" element={<Quiz />} />
        <Route path="/assessments" element={<Assessments />} />
        <Route path="/accountverify" element={<AccountVerify />} />
        <Route path="/verifyemail" element={<VerifyEmail />} />
        <Route path="/verifyemail/:userId/:token" element={<VerifyEmailAction />} />
        <Route path="/verifyemaillink" element={<VerifyEmailSendLink />} />
        {/* <Route path="/community/:communityHandle/about" element={<About />} /> */}
{/* 
        <Route
          path="/reverifyemail" element={<AccountVerify />}
        /> */}

        <Route
          path="/dashboard"
          element={<ProtectedRoute element={<Dashboard />} />}
        />

        <Route
          path="/cart"
          element={<ProtectedRoute element={<CartPage />} />}
        />

        {/* <Route
          path="/settings"
          element={<ProtectedRoute element={<Setting />} />}
        /> */}

        {/* <Route
          path="/communities"
          element={<ProtectedRoute element={<Communities />} />}
        /> */}
        {/* <Route
          path="/chats"
          element={<ProtectedRoute element={<ChatBox />} />}
        /> */}
        {/* <Route
          path="/profile"
          element={<ProtectedRoute element={<Profile communityData={communityData} />} />}
        /> */}
        {/* <Route
          path="/edit-profile"
          element={<ProtectedRoute element={<EditProfile />} />}
        /> */}
        {/* <Route path="/community/:communityHandle" element={<ProtectedRoute element={<Community
          communityData={communityData} setCommunityData={setCommunityData} />} />} /> */}

        {/* <Route path="/community/:communityHandle/posts" element={<ProtectedRoute element={<Posts />} />} /> */}
        {/* <Route path="/community/:communityHandle/actions/:userid/:cid" element={<ProtectedRoute element={<AcceptCommunity />} />} />
        <Route path="/community/:communityHandle/actions/:userid/:cid" element={<ProtectedRoute element={<AcceptInviteCommunity />} />} />
        <Route path="/community/:communityHandle/users/:cid/:caid" element={<ProtectedRoute element={< MemberList />} />} /> */}

        {/* <Route
          path="/community/:communityHandle/posts/:postId"
          element={<ProtectedRoute element={<Post />} />}
        /> */}



        {/* <Route path="/community/:slug/group" element={<Group />} />
        <Route path="/community/:slug/:slug/group-overview" element={<DiscussionOverview />} />
        <Route path="/community/:slug/discussion" element={<Discussion />} />
        <Route path="/community/:communityHandle/products" element={<ProtectedRoute element={<Products
          communityData={communityData} setCommunityData={setCommunityData} />} />}/>

        <Route path="/community/:slug/:slug/discussion-overview" element={<DiscussionOverview />} />
        <Route path="/vendorRegister" element={<ProtectedRoute element={<VendorRegister />} />} /> */}

      </Routes>

      {/* <Routes>
        <Route path="/admin" element={<Admin />} />
        <Route path="/" element={<Home />} />
        <Route path="/unauthorized" element={<Unauthorized />} />
        <Route path="*" element={<NotFound />} />
        <Route path="/developer" element={<Development />} />
        <Route path="/book-demo" element={<BookingForm />} />
        <Route path="/view-demo" element={<ViewDemo />} />
        <Route path="/faqs" element={<Faqs />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/reset-password" element={<ResetPassword/>} />

        <Route
          path="/admin/dashboard"
          element={
            <ProtectedRoute element={<Dashboard />} requiredAccess="user" />
          }
        />
        <Route
          path="/admin/products"
          element={
            <ProtectedRoute element={<Products />} requiredAccess="admin" />
          }
        />
        <Route
          path="/admin/users"
          element={<ProtectedRoute element={<Users />} requiredAccess="user" />}
        />
        <Route
          path="/admin/leads"
          element={<ProtectedRoute element={<Leads />} requiredAccess="user" />}
        />
        <Route
          path="/admin/add-products"
          element={
            <ProtectedRoute element={<AddProducts />} requiredAccess="user" />
          }
        />
        <Route
          path="/admin/support"
          element={
            <ProtectedRoute element={<Support />} requiredAccess="user" />
          }
        />
        <Route
          path="/admin/profile"
          element={
            <ProtectedRoute element={<Profile />} requiredAccess="user" />
          }
        />
        <Route
          path="/admin/product/:id"
          element={
            <ProtectedRoute element={<Product />} requiredAccess="user" />
          }
        />
        <Route
          path="/admin/add-user"
          element={
            <ProtectedRoute element={<AddUser />} requiredAccess="user" />
          }
        />
        <Route
          path="/admin/settings"
          element={
            <ProtectedRoute element={<Settings />} requiredAccess="user" />
          }
        />
        <Route
          path="/admin/organizations"
          element={
            <ProtectedRoute element={<Organizations />} requiredAccess="user" />
          }
        />
        <Route
          path="/admin/organization/:id"
          element={
            <ProtectedRoute element={<Organization />} requiredAccess="user" />
          }
        />
        <Route
          path="/admin/configurations"
          element={
            <ProtectedRoute
              element={<Configurations />}
              requiredAccess="user"
            />
          }
        />
        <Route
          path="/admin/inquiry"
          element={
            <ProtectedRoute element={<Inquiry />} requiredAccess="master" />
          }
        />
      </Routes> */}
    </BrowserRouter>
  );
};

export default RoutePage;
