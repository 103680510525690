import React from 'react';
import Layout from '../components/layout'

const Teams = () => {
  // Sample data for partners
  const partners = [
    {
      name: 'Company A',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus vel est id justo vehicula suscipit.',
      imageUrl: '/images/companyA.jpg', // Replace with actual image URL or import image
    },
    {
      name: 'Company B',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus vel est id justo vehicula suscipit.',
      imageUrl: '/images/companyB.jpg', // Replace with actual image URL or import image
    },
    // Add more partners here
  ];

  return (
    <Layout>


<section class="py-24 bg-gray-50">
        <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div class="flex justify-between items-center flex-col lg:flex-row md:mt-20">
                <div class="w-full lg:w-1/2">
                    <h2
                        class="font-manrope text-5xl text-gray-900 font-bold leading-[4rem] mb-7 text-center lg:text-left">
                        Our leading, strong & creative team</h2>
                    <p class="text-lg text-gray-500 mb-16 text-center lg:text-left">These people work on making our
                        product best.</p>
                    <button class="cursor-pointer py-3 px-8 w-60 bg-indigo-600 text-white text-base font-semibold transition-all duration-500 block text-center rounded-full hover:bg-indigo-700 mx-auto lg:mx-0">Join
                        our team</button>
                </div>
                <div class="w-full lg:w-1/2 lg:mt-0 md:mt-40 mt-16 max-lg:max-w-2xl">
                    <div class="grid grid-cols-1 min-[450px]:grid-cols-2 md:grid-cols-3 gap-8">
                        <img src="https://pagedone.io/asset/uploads/1696238644.png" alt="Team tailwind section"
                            class="w-44 h-56 rounded-2xl object-cover md:mt-20 mx-auto min-[450px]:mr-0" />
                        <img src="https://pagedone.io/asset/uploads/1696238665.png" alt="Team tailwind section"
                            class="w-44 h-56 rounded-2xl object-cover mx-auto min-[450px]:ml-0 md:mx-auto" />
                        <img src="https://pagedone.io/asset/uploads/1696238684.png" alt="Team tailwind section"
                            class="w-44 h-56 rounded-2xl object-cover md:mt-20 mx-auto min-[450px]:mr-0 md:ml-0" />
                        <img src="https://pagedone.io/asset/uploads/1696238702.png" alt="Team tailwind section"
                            class="w-44 h-56 rounded-2xl object-cover mx-auto min-[450px]:ml-0 md:mr-0 md:ml-auto" />
                        <img src="https://pagedone.io/asset/uploads/1696238720.png" alt="Team tailwind section"
                            class="w-44 h-56 rounded-2xl object-cover md:-mt-20 mx-auto min-[450px]:mr-0 md:mx-auto" />
                        <img src="https://pagedone.io/asset/uploads/1696238737.png" alt="Team tailwind section"
                            class="w-44 h-56 rounded-2xl object-cover mx-auto min-[450px]:ml-0 md:mr-0" />

                    </div>
                </div>
            </div>
        </div>
    </section>
                                            



    <div class="py-20 bg-gray-50">
    <div class="container mx-auto px-6 md:px-12 xl:px-32">
        <div class="mb-16 text-center">
            <h2 class="mb-4 text-center text-2xl text-gray-900 font-bold md:text-4xl">Tailus blocks leadership</h2>
            <p class="text-gray-600 lg:w-8/12 lg:mx-auto">Tailus prides itself not only on award-winning technology, but also on the talent of its people of some of the brightest minds and most experienced executives in business.</p>
        </div>
        <div class="grid gap-12 items-center md:grid-cols-3">
            <div class="space-y-4 text-center">
                <img class="w-64 h-64 mx-auto object-cover rounded-xl md:w-40 md:h-40 lg:w-64 lg:h-64" 
                    src="https://tailus.io/sources/blocks/classic/preview/images/woman1.jpg" alt="woman" loading="lazy" width="640" height="805"/>
                <div>
                    <h4 class="text-2xl">Hentoni Doe</h4>
                    <span class="block text-sm text-gray-500">CEO-Founder</span>
                </div>
            </div>
            <div class="space-y-4 text-center">
                <img class="w-64 h-64 mx-auto object-cover rounded-xl md:w-48 md:h-64 lg:w-64 lg:h-80" 
                    src="https://tailus.io/sources/blocks/classic/preview/images/man.jpg" alt="man" loading="lazy" width="1000" height="667"/>
                <div>
                    <h4 class="text-2xl">Jonathan Doe</h4>
                    <span class="block text-sm text-gray-500">Chief Technical Officer</span>
                </div>
            </div>
            <div class="space-y-4 text-center">
                <img class="w-64 h-64 mx-auto object-cover rounded-xl md:w-40 md:h-40 lg:w-64 lg:h-64" 
                    src="https://tailus.io/sources/blocks/classic/preview/images/woman.jpg" alt="woman" loading="lazy" width="1000" height="667"/>
                <div>
                    <h4 class="text-2xl">Anabelle Doe</h4>
                    <span class="block text-sm text-gray-500">Chief Operations Officer</span>
                </div>
            </div>
        </div>
    </div>
</div>



<section class="relative py-20 md:py-32 overflow-hidden bg-gray-50">
  <img class="absolute top-0 left-0 mt-64 sm:mt-20 lg:ml-20" src="saturn-assets/images/team/full-blue-light.png" alt=""/>
  <img class="absolute bottom-0 right-0 mr-20 mb-40 sm:mb-0 lg:-mb-12" src="saturn-assets/images/team/full-orange-light.png" alt=""/>
  <div class="relative container px-4 mx-auto">
    <div class="max-w-7xl mx-auto">
      <div class="max-w-2xl mx-auto mb-20 text-center">
        <h1 class="font-heading text-5xl xs:text-6xl md:text-7xl font-bold text-gray-900 mb-8">
          <span>Get to know our amazing</span>
          <span class="font-serif italic">Team</span>
        </h1>
        <p class="text-lg text-gray-500 mb-6">Mi turpis turpis in justo pellentesque id nibh praesent.</p>
        <a class="inline-flex py-4 px-6 items-center text-orange-900 font-semibold bg-gray-50 border border-gray-200 hover:border-orange-900 rounded-full transition duration-200" href="#">
          <span class="mr-4">See More Team</span>
          <span>
            <svg width="8" height="12" viewbox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6.83 5.28999L2.59 1.04999C2.49704 0.956266 2.38644 0.881872 2.26458 0.831103C2.14272 0.780334 2.01202 0.754196 1.88 0.754196C1.74799 0.754196 1.61729 0.780334 1.49543 0.831103C1.37357 0.881872 1.26297 0.956266 1.17 1.04999C0.983753 1.23736 0.879211 1.49081 0.879211 1.75499C0.879211 2.01918 0.983753 2.27263 1.17 2.45999L4.71 5.99999L1.17 9.53999C0.983753 9.72736 0.879211 9.98081 0.879211 10.245C0.879211 10.5092 0.983753 10.7626 1.17 10.95C1.26344 11.0427 1.37426 11.116 1.4961 11.1658C1.61794 11.2155 1.7484 11.2408 1.88 11.24C2.01161 11.2408 2.14207 11.2155 2.26391 11.1658C2.38575 11.116 2.49656 11.0427 2.59 10.95L6.83 6.70999C6.92373 6.61703 6.99813 6.50643 7.04889 6.38457C7.09966 6.26271 7.1258 6.13201 7.1258 5.99999C7.1258 5.86798 7.09966 5.73728 7.04889 5.61542C6.99813 5.49356 6.92373 5.38296 6.83 5.28999Z" fill="currentColor"></path>
            </svg>
          </span>
        </a>
      </div>
      <div class="flex flex-wrap -mx-4 -mb-8">
        {/* <div class="w-full md:w-1/2 lg:w-1/3 px-4 mb-8">
          <div class="max-w-md mx-auto py-10 px-6 text-center bg-white rounded-md">
            <img class="w-24 h-24 block mb-6 mx-auto" src="saturn-assets/images/team/avatar-sm-circle1.png" alt="">
            <h5 class="text-2xl font-bold text-gray-900 mb-2">Marvin Black</h5>
            <span class="block text-orange-900 mb-3">Founder</span>
            <div class="flex items-center justify-center">
              <a class="flex mr-2 w-8 h-8 items-center justify-center bg-gray-100 rounded-full hover:bg-gray-200 transition duration-200" href="#">
                <img src="saturn-assets/images/team/icon-facebook-black.svg" alt="">
              </a>
              <a class="flex mr-2 w-8 h-8 items-center justify-center bg-gray-100 rounded-full hover:bg-gray-200 transition duration-200" href="#">
                <img src="saturn-assets/images/team/icon-linkedin-black.svg" alt="">
              </a>
              <a class="flex w-8 h-8 items-center justify-center bg-gray-100 rounded-full hover:bg-gray-200 transition duration-200" href="#">
                <img src="saturn-assets/images/team/icon-instagram-black.svg" alt="">
              </a>
            </div>
          </div>
        </div>
        <div class="w-full md:w-1/2 lg:w-1/3 px-4 mb-8">
          <div class="max-w-md mx-auto py-10 px-6 text-center bg-white rounded-md">
            <img class="w-24 h-24 block mb-6 mx-auto" src="saturn-assets/images/team/avatar-sm-circle2.png" alt="">
            <h5 class="text-2xl font-bold text-gray-900 mb-2">Annette McKinney</h5>
            <span class="block text-orange-900 mb-3">Nursing Assistant</span>
            <div class="flex items-center justify-center">
              <a class="flex mr-2 w-8 h-8 items-center justify-center bg-gray-100 rounded-full hover:bg-gray-200 transition duration-200" href="#">
                <img src="saturn-assets/images/team/icon-facebook-black.svg" alt="">
              </a>
              <a class="flex mr-2 w-8 h-8 items-center justify-center bg-gray-100 rounded-full hover:bg-gray-200 transition duration-200" href="#">
                <img src="saturn-assets/images/team/icon-linkedin-black.svg" alt="">
              </a>
              <a class="flex w-8 h-8 items-center justify-center bg-gray-100 rounded-full hover:bg-gray-200 transition duration-200" href="#">
                <img src="saturn-assets/images/team/icon-instagram-black.svg" alt="">
              </a>
            </div>
          </div>
        </div>
        <div class="w-full md:w-1/2 lg:w-1/3 px-4 mb-8">
          <div class="max-w-md mx-auto py-10 px-6 text-center bg-white rounded-md">
            <img class="w-24 h-24 block mb-6 mx-auto" src="saturn-assets/images/team/avatar-sm-circle3.png" alt="">
            <h5 class="text-2xl font-bold text-gray-900 mb-2">Jerome Bell</h5>
            <span class="block text-orange-900 mb-3">Medical Assistant</span>
            <div class="flex items-center justify-center">
              <a class="flex mr-2 w-8 h-8 items-center justify-center bg-gray-100 rounded-full hover:bg-gray-200 transition duration-200" href="#">
                <img src="saturn-assets/images/team/icon-facebook-black.svg" alt="">
              </a>
              <a class="flex mr-2 w-8 h-8 items-center justify-center bg-gray-100 rounded-full hover:bg-gray-200 transition duration-200" href="#">
                <img src="saturn-assets/images/team/icon-linkedin-black.svg" alt="">
              </a>
              <a class="flex w-8 h-8 items-center justify-center bg-gray-100 rounded-full hover:bg-gray-200 transition duration-200" href="#">
                <img src="saturn-assets/images/team/icon-instagram-black.svg" alt="">
              </a>
            </div>
          </div>
        </div>
        <div class="w-full md:w-1/2 lg:w-1/3 px-4 mb-8">
          <div class="max-w-md mx-auto py-10 px-6 text-center bg-white rounded-md">
            <img class="w-24 h-24 block mb-6 mx-auto" src="saturn-assets/images/team/avatar-sm-circle4.png" alt="">
            <h5 class="text-2xl font-bold text-gray-900 mb-2">Cody Henry</h5>
            <span class="block text-orange-900 mb-3">President of Sales</span>
            <div class="flex items-center justify-center">
              <a class="flex mr-2 w-8 h-8 items-center justify-center bg-gray-100 rounded-full hover:bg-gray-200 transition duration-200" href="#">
                <img src="saturn-assets/images/team/icon-facebook-black.svg" alt="">
              </a>
              <a class="flex mr-2 w-8 h-8 items-center justify-center bg-gray-100 rounded-full hover:bg-gray-200 transition duration-200" href="#">
                <img src="saturn-assets/images/team/icon-linkedin-black.svg" alt="">
              </a>
              <a class="flex w-8 h-8 items-center justify-center bg-gray-100 rounded-full hover:bg-gray-200 transition duration-200" href="#">
                <img src="saturn-assets/images/team/icon-instagram-black.svg" alt="">
              </a>
            </div>
          </div>
        </div> */}
        <div class="w-full md:w-1/2 lg:w-1/3 px-4 mb-8">
          <div class="max-w-md mx-auto py-10 px-6 text-center bg-white rounded-md">
            <img class="w-24 h-24 block mb-6 mx-auto" src="saturn-assets/images/team/avatar-sm-circle5.png" alt=""/>
            <h5 class="text-2xl font-bold text-gray-900 mb-2">Courtney Fisher</h5>
            <span class="block text-orange-900 mb-3">Web Designer</span>
            <div class="flex items-center justify-center">
              <a class="flex mr-2 w-8 h-8 items-center justify-center bg-gray-100 rounded-full hover:bg-gray-200 transition duration-200" href="#">
                <img src="saturn-assets/images/team/icon-facebook-black.svg" alt=""/>
              </a>
              <a class="flex mr-2 w-8 h-8 items-center justify-center bg-gray-100 rounded-full hover:bg-gray-200 transition duration-200" href="#">
                <img src="saturn-assets/images/team/icon-linkedin-black.svg" alt=""/>
              </a>
              <a class="flex w-8 h-8 items-center justify-center bg-gray-100 rounded-full hover:bg-gray-200 transition duration-200" href="#">
                <img src="saturn-assets/images/team/icon-instagram-black.svg" alt=""/>
              </a>
            </div>
          </div>
        </div>
        <div class="w-full md:w-1/2 lg:w-1/3 px-4 mb-8">
          <div class="max-w-md mx-auto py-10 px-6 text-center bg-white rounded-md">
            <img class="w-24 h-24 block mb-6 mx-auto" src="saturn-assets/images/team/avatar-sm-circle6.png" alt=""/>
            <h5 class="text-2xl font-bold text-gray-900 mb-2">James Wilson</h5>
            <span class="block text-orange-900 mb-3">Dog Trainer</span>
            <div class="flex items-center justify-center">
              <a class="flex mr-2 w-8 h-8 items-center justify-center bg-gray-100 rounded-full hover:bg-gray-200 transition duration-200" href="#">
                <img src="saturn-assets/images/team/icon-facebook-black.svg" alt=""/>
              </a>
              <a class="flex mr-2 w-8 h-8 items-center justify-center bg-gray-100 rounded-full hover:bg-gray-200 transition duration-200" href="#">
                <img src="saturn-assets/images/team/icon-linkedin-black.svg" alt=""/>
              </a>
              <a class="flex w-8 h-8 items-center justify-center bg-gray-100 rounded-full hover:bg-gray-200 transition duration-200" href="#">
                <img src="saturn-assets/images/team/icon-instagram-black.svg" alt=""/>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

      <div className="bg-gray-100 min-h-screen py-8">
        <div className="max-w-6xl mx-auto">
          <h1 className="text-3xl font-semibold text-center mb-8">Our Partners</h1>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {partners.map((partner, index) => (
              <div key={index} className="bg-white shadow-md rounded-lg overflow-hidden hover:shadow-lg transition duration-300">
                <img src={partner.imageUrl} alt={partner.name} className="w-full h-40 object-cover" />
                <div className="p-4">
                  <h2 className="text-xl font-semibold mb-2">{partner.name}</h2>
                  <p className="text-gray-600">{partner.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Teams;