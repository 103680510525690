import React from "react";
import {
  FiSearch,
  FiMessageCircle,
  FiAward,
  FiDollarSign,
  FiUserPlus,
  FiBell,
  FiHelpCircle,
} from "react-icons/fi";
import Layout from "../components/layout";

const HelpCenter = () => {
  // Sample FAQ data
  const faqs = [
    {
      question: "How do I reset my password?",
      answer:
        'To reset your password, go to the login page and click on the "Forgot Password" link.',
    },
    {
      question: "How can I contact customer support?",
      answer:
        "You can contact our customer support team through the support chat or by emailing us at support@example.com.",
    },
    // Add more FAQs here
  ];

  return (
    <Layout>
      <div className="bg-gray-100 min-h-screen">
        {/* Main Banner */}
        <div className="bg-blue-500 py-8 text-white text-center relative">
          <h1 className="text-4xl font-bold">Welcome to Help Center</h1>
          <p className="mt-4">How can we assist you today?</p>
          {/* Search Box */}
          <div className="max-w-md mx-auto mt-6 relative">
            <input
              type="text"
              placeholder="Search for help..."
              className="w-full px-4 py-2 rounded-lg focus:outline-none focus:ring focus:border-blue-300"
            />
            <FiSearch className="h-6 w-6 absolute top-1/2 transform -translate-y-1/2 right-4 text-gray-500" />
          </div>
        </div>

        {/* Content Banners */}
        <div className="max-w-6xl mx-auto mt-8">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {/* Support Chat */}
            <div className="bg-white rounded-lg shadow-md p-6 flex items-center space-x-4">
              <FiMessageCircle className="h-8 w-8 text-blue-500" />
              <div>
                <h2 className="text-xl font-semibold mb-2">Support Chat</h2>
                <p>Connect with our support team for instant assistance.</p>
              </div>
            </div>
            {/* Verify Certificate */}
            <div className="bg-white rounded-lg shadow-md p-6 flex items-center space-x-4">
              <FiAward className="h-8 w-8 text-blue-500" />
              <div>
                <h2 className="text-xl font-semibold mb-2">
                  Verify Certificate
                </h2>
                <p>Learn how to verify your certificate with our platform.</p>
              </div>
            </div>
            {/* Transactions */}
            <div className="bg-white rounded-lg shadow-md p-6 flex items-center space-x-4">
              <FiDollarSign className="h-8 w-8 text-blue-500" />
              <div>
                <h2 className="text-xl font-semibold mb-2">Transactions</h2>
                <p>
                  Get information about your transaction history and status.
                </p>
              </div>
            </div>
            {/* Enrollment */}
            <div className="bg-white rounded-lg shadow-md p-6 flex items-center space-x-4">
              <FiUserPlus className="h-8 w-8 text-blue-500" />
              <div>
                <h2 className="text-xl font-semibold mb-2">Enrollment</h2>
                <p>
                  Find guidance on how to enroll in our programs or courses.
                </p>
              </div>
            </div>
            {/* Account & Notifications */}
            <div className="bg-white rounded-lg shadow-md p-6 flex items-center space-x-4">
              <FiBell className="h-8 w-8 text-blue-500" />
              <div>
                <h2 className="text-xl font-semibold mb-2">
                  Account & Notifications
                </h2>
                <p>
                  Manage your account settings and notification preferences.
                </p>
              </div>
            </div>
            {/* FAQs */}
            <div className="bg-white rounded-lg shadow-md p-6 flex items-center space-x-4">
              <FiHelpCircle className="h-8 w-8 text-blue-500" />
              <div>
                <h2 className="text-xl font-semibold mb-2">FAQs</h2>
                {/* FAQs List */}
                <ul className="list-disc pl-6">
                  {faqs.map((faq, index) => (
                    <li key={index} className="mb-2">
                      <button className="font-semibold">{faq.question}</button>
                      {/* FAQ Answer */}
                      <p className="text-gray-600">{faq.answer}</p>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default HelpCenter;