import React from "react";
import { Link, NavLink } from "react-router-dom";
// import "../styles/sidebar.css";

const Sidebar = () => {
  return (
    <div className="p-4 flex flex-col h-full justify-between bg-[#fff] text-[#000] w-full border right-1">
      <div className="courseTopic flex justify-center flex-col items-start">
      <NavLink to={`/course/test/topic`} activeClassName="active">HTML 1</NavLink>
      <NavLink to={`/course/test/4`} activeClassName="active">HTML 2</NavLink>
      <NavLink to={`/course/test/3`} activeClassName="active">HTML 3</NavLink>
      <NavLink to={`/course/test/2`} activeClassName="active">HTML 4</NavLink>
      <NavLink to={`/course/test/1`} activeClassName="active">HTML 5</NavLink>
      </div>
    </div>
  );
};

export default Sidebar;
