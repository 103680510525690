import React from "react";
import Layout from "../components/layout";
import { useUser } from "../config/UserProvider";

const Profile = () => {
  const { userData } = useUser();
  console.log("user 7 profile page",userData);
  return (
    <Layout>
      <div className={`p-4 md:p-6 rounded-lg flex justify-center mx-auto my-8 `}>
        <div className="flex flex-col md:flex-row md:items-start gap-10 w-full md:w-10/12 ">
          <div className="md:w-1/2 bg-white rounded-xl pb-10">
            <div className="flex flex-col items-center p-4">
              <img
                className="w-32 h-32 rounded-full"
                src="https://placehold.co/128x128"
                alt="Profile Picture"
              />
              <h2 className={`mt-4 font-bold text-xl `}>{userData?.name}</h2>
              <div className={`text-zinc-600  mt-2 w-11/12`}>
                <div className="flex justify-between">
                  Total Course{" "}
                  <span className="font-semibold text-black">30</span>
                </div>
                <div className="flex justify-between">
                  Ratings{" "}
                  <span className="font-semibold text-yellow-400">
                    4.9(153)
                  </span>
                </div>
                <div className="flex justify-between">
                  Experiences{" "}
                  <span className="font-semibold text-black">10 Years</span>
                </div>
                <div className="flex justify-between">
                  Graduated{" "}
                  <span className="font-semibold text-black">Yes</span>
                </div>
                <div className="flex justify-between">
                  Language{" "}
                  <span className="font-semibold text-black">
                    English, French
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="md:1/2 bg-white rounded-xl">
            <div className="p-4">
              <h3 className={`font-bold text-lg `}>About Kristin</h3>
              <p className={`text-zinc-600  mt-2`}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis
                ipsum suspendisse ultrices gravida. Risus commodo viverra
                maecenas accumsan lacus vel facilisis consectetur adipiscing
                elit.
              </p>
              <h3 className={`font-bold text-lg  mt-4`}>Certification</h3>
              <p className={`text-zinc-600  mt-2`}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis
                ipsum suspendisse ultrices gravida. Risus commodo viverra
                maecenas accumsan lacus vel facilisis consectetur adipiscing
                elit.
              </p>
              <h3 className={`font-bold text-lg  mt-4`}>Courses (2)</h3>
              <CourseCard />
              <CourseCard />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Profile;

const CourseCard = () => {
  return (
    <div className="p-4 bg-zinc-100 dark:bg-zinc-700 rounded-lg mt-2">
      <div className="flex items-center">
        <img
          className="w-16 h-16"
          src="https://placehold.co/64x64"
          alt="Course Thumbnail"
        />
        <div className="ml-4">
          <h4 className={`font-bold `}>Product Management</h4>
          <p className={`text-sm `}>Basic - Course</p>
          <p className={`text-sm `}>
            Product Management Masterclass, you will learn with Sarah Johnson -
            Head of Product Customer Platform Gojek Indonesia.
          </p>
          <div className="flex items-baseline mt-2">
            <span className={`text-lg font-bold text-red-500 `}>$380</span>
            <span className={`text-sm text-zinc-500 line-through ml-2`}>
              $500
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
