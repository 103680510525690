import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import AdminFooter from "./adminFooter";
import AdminHeader from "./adminHeader";
import Sidebar from "./sidebar";
import Footer from "./footer";
import Header from "./header";

const Layout = ({ children }) => {
  const location = useLocation();
  const { pathname } = location;
  const isAdminRoute = [
    "/student/addtopper",
    "/student/view-student",
    "/student/topperslist",
    "/student",
    "/dashboard",
    "/orders",
    "/courses",
    "/profile",
  ].includes(pathname);
  const isAdminCourseRoute = ["/course/test/topic"].includes(pathname);

  const [isTopicDrawer, setIsTopicDrawer] = useState(false);

  return (
    <div className="layoutContainer flex flex-col h-screen relative">
      {isAdminRoute ? (
        <React.Fragment>
          <Header />
          <main>{children}</main>
          <AdminFooter />
        </React.Fragment>
      ) : isAdminCourseRoute ? (
        <React.Fragment>
          <Header />
          <AdminHeader/>
          <div className="flex w-full h-full relative">
          {isTopicDrawer === true?
            <div className="w-1/6">
          <Sidebar/>
          </div>:null
          }
          <button
        onClick={() => setIsTopicDrawer(!isTopicDrawer)}
        className="text-xl fixed top-1/2 bg-red-500 text-white left-0"
      >
        {isTopicDrawer ? "Close" : "Topic"}
      </button>

          <main className="w-full">{children}</main>
          </div>
          <AdminFooter />
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Header />
          <main>{children}</main>
          <Footer />
        </React.Fragment>
      )}
    </div>
  );
};

export default Layout;

// import React, { useEffect, useState } from "react";
// import Header from "./header";
// import Footer from "./footer";
// import AdminFooter from "./adminFooter";
// import AdminHeader from "./adminHeader";
// import Sidebar from "./sidebar";
// import { Link } from "react-router-dom";

// const Layout = ({ children }) => {
//   const { query } = useRouter();
//   const UserID = query.id;

//   const [isAdminRoute, setIsAdminRoute] = useState(false);
//   const { asPath } = useRouter();
//   const allpath = [
//     "/student/addtopper",
//     `/student/view-student?id=${UserID}`,
//     "/student/topperslist",
//     "/student",
//     "/student/home",
//     "/student/content_upload",
//     "/student/coursemapping",
//     "/student/courses",
//   ];
//   useEffect(() => {
//     allpath.includes(asPath) ? setIsAdminRoute(true) : setIsAdminRoute(false);
//   }, [allpath, asPath]);
//   return (
//     <div>
//       {!isAdminRoute ? (
//         <React.Fragment>
//       <AdminFooter/>
//       <AdminHeader/>
//       <Sidebar/>
//           <Link href="/form" style={{ cursor: "pointer" }}>
//             <div className={styles.buttontry}>
//               <b>Enquire Now</b>
//             </div>
//           </Link>
//         </React.Fragment>
//       ) : (
//         ""
//       )}
//       <main>{children}</main>

//       {!isAdminRoute ? <Footer /> : ""}
//     </div>
//   );
// };

// export default Layout;

// import React, { useState, useEffect } from "react";
// import Header from "./header";
// import Footer from "./footer";
// import { ProfileProvider } from "../config/UserProvider";

// const Layout = ({ children }) => {
//   return (
//     // <ProfileProvider>
//       <div className="layoutContainer flex flex-col h-screen relative">
//         <Header />
//         <div className="relative">
//           <main className="">{children}</main>
//         </div>
//         <Footer className="mt-auto" />
//       </div>
//     // </ProfileProvider>
//   );
// };

// export default Layout;

// import React, { useState, useEffect } from "react";
// import Header from "./header";
// import Footer from "./footer";
// import Sidebar from "./sidebar";
// import { useLocation } from "react-router-dom";
// import MainFooter from "./mainFooter";
// import MainHeader from "./mainHeader";

// const Layout = ({ children }) => {
//   const [showSidebar, setShowSidebar] = useState(false);
//   const [isMobile, setIsMobile] = useState(false);
//   const [isNotification, setIsNotification] = useState(false);
//   const [isDropdownOpen, setIsDropdownOpen] = useState(false);

//   const location = useLocation();

//   useEffect(() => {
//     function handleResize() {
//       setIsMobile(window.innerWidth < 640);
//     }

//     handleResize();
//     window.addEventListener("resize", handleResize);

//     return () => {
//       window.removeEventListener("resize", handleResize);
//     };
//   }, []);

//   const toggleSidebar = () => {
//     setShowSidebar(!showSidebar);
//   };

//   const isRouteAdmin = location.pathname.startsWith("/admin");

//   const handlePopup = () => {
//     setIsNotification(false)
//     setIsDropdownOpen(false)
//   }

//   return (

//     <div className="layoutContainer flex flex-col h-screen relative" >
//       {isRouteAdmin ? <Header toggleSidebar={toggleSidebar} isSidebarOpen={showSidebar} setIsNotification={setIsNotification} isNotification={isNotification} isDropdownOpen={isDropdownOpen} setIsDropdownOpen={setIsDropdownOpen} /> : <MainHeader />}
//       <div className="flex flex-row flex-1 relative">
//         {isRouteAdmin && (
//           <div
//             className={`lg:w-1/6 bg-[#fff] ${isMobile
//               ? `absolute top-0 left-0 w-full  ${showSidebar ? "block" : "hidden"
//               }`
//               : "block"
//               }`}
//           >
//             <Sidebar />
//           </div>
//         )}
//         <main className={`${isMobile && showSidebar ? "sss" : ""} w-full`} onClick={handlePopup}>
//           {children}
//         </main>
//       </div>
//       {isRouteAdmin ? <Footer className="mt-auto" /> : <MainFooter className="mt-auto" />}
//     </div>
//   );
// };

// export default Layout;
