import React from "react";
import { Link, NavLink } from "react-router-dom";

const AdminHeader = () => {
  return (
    <div className="bg-gray-550 courseHeader">
      <div class="flex items-center relative px-4 justify-start bg-[#4D2C5E]">
        <NavLink
          className="text-white px-3 py-1"
          activeClassName="active"
          to="/course/test/topic"
        >
          HTML
        </NavLink>
      </div>
    </div>
  );
};

export default AdminHeader;
