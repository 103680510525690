import axios from "axios";
import React, { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { apiurl } from "../config/config";
import LoaderSpinner from "../components/loader";
import { toast } from "react-toastify";

const VerifyEmail = () => {
  const { userid, token } = useParams();
  const [loading, setLoading] = useState(false);
  const [mailVerify, setMailVerify] = useState(true);
  const [reVerifyMail, setReVerifyMail] = useState(false);
  const [successAlert, setSuccessAlert] = useState(false);
  const [sendMailAlert, setSendMailAlert] = useState(false);
  const [email, setEmail] = useState("");
  const navigate = useNavigate();

  const verifyEmail = async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        `${apiurl}/user/email-verify/${userid}/${token}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log("Verification response:", response.data);

      if (response?.data.success === true) {
        setLoading(false);
        setSuccessAlert(true);
        // navigate('/login');
        setReVerifyMail(false);
        setMailVerify(false);
      } else if (response?.data.success === false) {
        toast.error("Please re-verify the email");
        setLoading(false);
        setReVerifyMail(true);
        setMailVerify(false);
      } else {
        setMailVerify(true);
        setReVerifyMail(false);
        setLoading(false);
      }
    } catch (error) {
      console.error("Verifying email error:", error);
      setLoading(false);
      setMailVerify(true);
    }
  };

  const handleReVerifyMail = async () => {
    setLoading(true);
    if (email === "") {
      toast.error("Please fill in the email");
      setLoading(false);
      return;
    }
    try {
      const response = await axios.post(
        `${apiurl}/user/resend-verification-token`,
        { email }
      );
      console.log("resend-verification response >>>>>", response);

      if (response.status === 200) {
        setSendMailAlert(true);
        setLoading(false);
        setReVerifyMail(false);
      } else {
        toast.error("User not found please re-verify the email");
        setLoading(false);
      }
    } catch (error) {
      console.error("ReVerifyMail error", error);
      setLoading(false);
    }
  };

  return (
    <div>
      {loading ? <LoaderSpinner text="Verifying..." /> : null}
      <div>
        <div className="fixed inset-0 overflow-y-auto shadow-2xl">
          <div className="flex items-center justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 transition-opacity">
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen"></span>
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div className="sm:flex sm:items-start">
                <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
                  <svg
                    className="h-6 w-6 text-green-600"
                    stroke="currentColor"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M5 13l4 4L19 7"
                    ></path>
                  </svg>
                </div>
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <h3 className="text-lg leading-6 font-medium text-gray-900">
                    Token Sent!
                  </h3>
                  <div className="mt-2">
                    <p className="text-sm leading-5 text-gray-500">
                      We have sent the token to your email. Please check your
                      email first, and then click on the link to redirect to
                      login.
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <span className="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
                  <Link
                    to="/login"
                    className="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-green-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-green-500 focus:outline-none focus:shadow-outline-green transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                  >
                    Close
                  </Link>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyEmail;
