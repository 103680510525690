import { BsPlayCircleFill } from "react-icons/bs";
import { FaStar } from "react-icons/fa";
import { MdDone } from "react-icons/md";
import Layout from "../components/layout";
import axios from "axios";
import { apiurl } from "../config/config";
import { useNavigate, useParams } from "react-router-dom";
import { useUser } from "../config/UserProvider";

const Course = () => {
  const navigate = useNavigate();

  let {courseid} = useParams();
  let {userData} = useUser();
  let userId = userData?._id;


  console.log("user course",userData);
  console.log("console.log(navigate);",navigate);

  const atcCourseHandle = ()=>{

let config = {
  method: 'patch',
  maxBodyLength: Infinity,
  url: `${apiurl}/user/atc/${userId}/${courseid}`,
  headers: { }
};

axios.request(config)
.then((response) => {
  console.log(response?.data);
  if(response?.data?.success === true){
  navigate('/cart');
}
})
.catch((error) => {
  console.log(error);
});

  }


  return (
    <Layout>






{/* 
<div className="container mx-auto p-4">
      <h1 className="text-3xl font-semibold mb-4">PDF Viewer</h1>
      <div className="flex items-center justify-center">
        <embed src="/Lesson_ HTML5 Semantic Elements.pdf" type="pdf" width="800" height="600" />
      </div>
      <div className="flex items-center justify-center mt-4">
        <a href="/Lesson_ HTML5 Semantic Elements.pdf" download className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded inline-flex items-center">
          Download PDF
        </a>
      </div>
    </div> */}









    <div className="bg-gray-50 w-full pb-10 flex justify-center items-start ">
      {/* Start from here */}
      <div className="w-11/12 flex-col ">
        <div className="rounded-xl p-5 md:p-12 flex flex-wrap bg-white w-full gap-1 md:gap-14">
          <div className="rounded-lg overflow-hidden relative bg-white w-full md:w-1/2 max-h-[25rem] flex justify-center items-center">
            <img
              src="https://images.unsplash.com/photo-1513258496099-48168024aec0?q=80&w=1740&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
              alt=""
            />
            <button className="text-orange-400 text-[5rem] absolute ">
              <BsPlayCircleFill />
            </button>
          </div>
          <div className="flex flex-col gap-5 text-lg">
            <h1 className=" font-semibold text-2xl">
              Math - for Standard 3 Students | Episode 2
            </h1>
            <p>
              {" "}
              Number of Lessons :{" "}
              <span className="font-semibold">49 Lessons</span>
            </p>
            <p>
              {" "}
              Completion time: <span className="font-semibold">1 Month</span>
            </p>
            <p>
              {" "}
              Teacher
              <span className="font-semibold text-violet-500 uppercase">
                {" "}
                jassia andrew
              </span>
            </p>
            <p>
              {" "}
              Students have learned:{" "}
              <span className="font-semibold">12,000+</span>
            </p>
            <p className="flex items-center gap-2">
              Review:
              <span className="text-orange-400 flex items-center gap-3">
                <FaStar />
                <FaStar />
                <FaStar />
                <FaStar />
                <FaStar />
              </span>
            </p>
            <h1 className="font-xl">
              Price:
              <span className="font-semibold"> 200$</span>
            </h1>
            <div className="flex justify-between items-center">
              <button onClick={atcCourseHandle} className="bg-orange-400 rounded-lg px-3 py-2 md:px-14 md:py-4">
                Add to cart
              </button>
              <button className="bg-orange-100 rounded-lg px-3 py-2 md:px-14 md:py-4">
                Free trial lession
              </button>
            </div>
          </div>
        </div>

        <div className="mt-10">
          <div>
            <h1 className="text-2xl font-semibold">Course Details</h1>
            <p className=" text-lg my-5">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis
              ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas
              accumsan lacus vel facilisis consectetur adipiscing elit. Lorem
              ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
              tempor incididunt ut labore et dolore magna aliqua. Quis ipsum
              suspendisse ultrices gravida. Risus commodo viverra maecenas
              accumsan.
            </p>
          </div>
          <div>
            <h1 className="text-2xl font-semibold">Certification</h1>
            <p className=" text-lg my-5">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis
              ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas
              accumsan lacus vel facilisis consectetur adipiscing elit.{" "}
            </p>
          </div>
          <div>
            <h1 className="text-2xl font-semibold">Who this course is for </h1>
            <p className=" text-lg my-5">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis
              ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas
              accumsan lacus vel facilisis consectetur adipiscing elit.{" "}
            </p>
          </div>
          <div>
            <h1 className="text-2xl font-semibold">
              What you will learn in this course :{" "}
            </h1>
            <ul className="text-lg flex flex-col gap-3 my-5">
              <li className="flex items-center gap-5">
                <span className="bg-violet-600 text-xs rounded-full p-1 text-white">
                  <MdDone />
                </span>{" "}
                Lorem ipsum dolor sit amet, consectetur adipiscing elit{" "}
              </li>
              <li className="flex items-center gap-5">
                <span className="bg-violet-600 text-xs rounded-full p-1 text-white">
                  <MdDone />
                </span>{" "}
                Lorem ipsum dolor sit amet, consectetur adipiscing elit{" "}
              </li>
              <li className="flex items-center gap-5">
                <span className="bg-violet-600 text-xs rounded-full p-1 text-white">
                  <MdDone />
                </span>{" "}
                Lorem ipsum dolor sit amet, consectetur adipiscing elit{" "}
              </li>
              <li className="flex items-center gap-5">
                <span className="bg-violet-600 text-xs rounded-full p-1 text-white">
                  <MdDone />
                </span>{" "}
                Lorem ipsum dolor sit amet, consectetur adipiscing elit{" "}
              </li>
            </ul>
          </div>
        </div>

        {/* similar course container */}
        <div>
          {/* Similar course header */}
          <div className=" flex justify-between w-full my-10">
            <h1 className="text-4xl font-semibold">Similar Courses</h1>
            <button>see more {">"}</button>
          </div>

          <div className=" flex items-center justify-between gap-10 w-full my-10 flex-wrap">
            {/* Cards */}
            <div className="bg-white flex gap-8 p-5 rounded-xl">
              <img
                className="rounded-xl w-32"
                src="https://images.unsplash.com/photo-1513258496099-48168024aec0?q=80&w=1740&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
              />
              <div className="">
                <h1 className="capitalize font-semibold">
                  Product Management basic - course
                </h1>
                <p className="text-black/50 text-sm">
                  Product Management Masterclass, you will learn with Sarah
                  Johnson - Head of Product Customer Platform Gojek Indonesia.
                </p>
                <h1 className="font-xl text-orange-400 font-bold mt-3 text-xl">
                  500$
                  <span className="font-semibold text-gray-400"> 200$</span>
                </h1>
              </div>
            </div>
            {/*  */}
            <div className="bg-white flex gap-8 p-5 rounded-xl">
              <img
                className="rounded-xl w-32"
                src="https://images.unsplash.com/photo-1513258496099-48168024aec0?q=80&w=1740&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
              />
              <div className="">
                <h1 className="capitalize font-semibold">
                  Product Management basic - course
                </h1>
                <p className="text-black/50 text-sm">
                  Product Management Masterclass, you will learn with Sarah
                  Johnson - Head of Product Customer Platform Gojek Indonesia.
                </p>
                <h1 className="font-xl text-orange-400 font-bold mt-3 text-xl">
                  500$
                  <span className="font-semibold text-gray-400"> 200$</span>
                </h1>
              </div>
            </div>
            {/*  */}
            <div className="bg-white flex gap-8 p-5 rounded-xl">
              <img
                className="rounded-xl w-32"
                src="https://images.unsplash.com/photo-1513258496099-48168024aec0?q=80&w=1740&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
              />
              <div className="">
                <h1 className="capitalize font-semibold">
                  Product Management basic - course
                </h1>
                <p className="text-black/50 text-sm">
                  Product Management Masterclass, you will learn with Sarah
                  Johnson - Head of Product Customer Platform Gojek Indonesia.
                </p>
                <h1 className="font-xl text-orange-400 font-bold mt-3 text-xl">
                  500$
                  <span className="font-semibold text-gray-400"> 200$</span>
                </h1>
              </div>
            </div>
            {/*  */}
          </div>
        </div>

        <div className="bg-[#211903] rounded-xl flex flex-col items-center justify-center p-5 md:py-10 text-white">
          <h1 className="md:text-4xl text-xl">Do you still have any questions?</h1>
          <p className="my-4 md:text-[16px] text-[14px]">
            Don't hesitate to leave us your phone number. We will contact you to
            discuss any questions you may have
          </p>
          <div className="w-full md:w-1/3 rounded-xl overflow-hidden bg-orange-400 flex items-center">
            <input
              type="text"
              placeholder="Enter your phone number"
              className="py-4 px-5 bg-white w-full "
            />
            <button className=" h-full px-5  font-semibold ">Subscribe</button>
          </div>
        </div>
      </div>
    </div>
    </Layout>
  );
};

export default Course;