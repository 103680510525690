import React from 'react';
import Layout from '../components/layout';

const CourseCard = ({ title, code, summary, items, exams, progress }) => {
  return (
    <div className="bg-white shadow-md rounded-lg p-4 w-72 m-4">
      <div className="flex justify-between items-center">
        <h3 className="text-xl font-bold">{title}</h3>
        <span className="text-sm text-gray-500">{code}</span>
      </div>
      <div className="mt-4">
        <p className="text-sm font-semibold">Q. Items summary</p>
        <div className="mt-2">
          {summary.map((item, index) => (
            <div key={index} className="flex justify-between items-center mb-1">
              <span className="text-sm">{item.label}</span>
              <div className="w-40 bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
                <div
                  className={`bg-${item.color}-500 h-2.5 rounded-full`}
                  style={{ width: `${item.value}%` }}
                ></div>
              </div>
              <span className="text-sm font-semibold ml-2">{item.value}</span>
            </div>
          ))}
        </div>
      </div>
      <div className="mt-4 flex justify-between text-center">
        <div>
          <p className="text-2xl font-bold">{items}</p>
          <p className="text-sm text-gray-500">Items</p>
        </div>
        <div>
          <p className="text-2xl font-bold">{exams}</p>
          <p className="text-sm text-gray-500">Exams</p>
        </div>
      </div>
      {progress !== undefined && (
        <div className="mt-4">
          <p className="text-sm font-semibold">Creating course</p>
          <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700 mt-2">
            <div
              className="bg-yellow-500 h-2.5 rounded-full"
              style={{ width: `${progress}%` }}
            ></div>
          </div>
          <p className="text-sm text-gray-500 mt-1">{progress}%</p>
        </div>
      )}
    </div>
  );
};

const Assessments = () => {
  const courses = [
    {
      title: 'Mathematics CS 02',
      code: 'CS MTH2445',
      summary: [
        { label: 'New', color: 'blue', value: 100 },
        { label: 'Calibrated', color: 'green', value: 90 },
        { label: 'Pending', color: 'yellow', value: 40 },
        { label: 'Expired', color: 'red', value: 70 },
        { label: 'Released', color: 'purple', value: 20 },
        { label: 'Modified', color: 'gray', value: 10 },
      ],
      items: 53,
      exams: 25,
    },
    {
      title: 'Mathematics CS 02',
      code: 'CS MTH2445',
      summary: [
        { label: 'New', color: 'blue', value: 90 },
        { label: 'Calibrated', color: 'green', value: 80 },
        { label: 'Pending', color: 'yellow', value: 70 },
        { label: 'Expired', color: 'red', value: 60 },
        { label: 'Released', color: 'purple', value: 50 },
        { label: 'Modified', color: 'gray', value: 40 },
      ],
      items: 53,
      exams: 25,
    },
    {
      title: 'Mathematics CS 02',
      code: 'CS MTH2445',
      summary: [],
      items: 0,
      exams: 0,
      progress: 23,
    },
  ];

  return (
    <Layout>
    <div className="bg-gray-100 min-h-screen p-8">
      <div className="flex flex-wrap">
        {courses.map((course, index) => (
          <CourseCard key={index} {...course} />
        ))}
      </div>
    </div>
    </Layout>
  );
};

export default Assessments;
