import React from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { apiurl } from '../config/config';

const Checkout = ({ amount, courseId ,prefill }) => {
    const Navigate = useNavigate();
    const handlePayment = async () => {
        const orderUrl = `${apiurl}/api/payment/orders`;
        const orderData = await axios.post(orderUrl, { amount, currency: 'INR', receipt: `receipt_${courseId}` });

        const options = {
            key: 'rzp_test_le5xEUAhSP6hFW',
            amount: orderData.data.amount,
            currency: orderData.data.currency,
            name: 'Trident Life Science',
            description: 'Test Transaction',
            order_id: orderData.data.id,
            handler: async function (response) {
                const paymentData = {
                    razorpay_order_id: response.razorpay_order_id,
                    razorpay_payment_id: response.razorpay_payment_id,
                    razorpay_signature: response.razorpay_signature,
                };

                const verifyUrl = `${apiurl}/api/payment/verify`;
                const result = await axios.post(verifyUrl, paymentData);

                if (result.data.status === 'success') {
                    // alert('Payment Successful');
                    Navigate('/thankyou');
                    // Handle success (e.g., update database, notify user)
                } else {
                    // alert('Payment Verification Failed');
                    Navigate('/error');
                }
            },
            prefill:prefill,
            // prefill: {
            //     name: 'Customer Name',
            //     email: 'customer@example.com',
            //     contact: '9999999999'
            // },
            notes: {
                address: 'Corporate Office'
            },
            theme: {
                color: '#F37254'
            }
        };

        const rzp1 = new window.Razorpay(options);
        rzp1.open();
    };

    return (
        <button className='bg-[#4D2C5E] hover:bg-[#FF7426] text-xl text-white font-semibold  py-3 uppercase w-full rounded-md' onClick={handlePayment}>
            Pay Now
        </button>
    );
};

export default Checkout;