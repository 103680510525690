import React, { useRef, useEffect } from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import QRCode from "qrcode.react";

// Create styles
const styles = StyleSheet.create({
  page: {
    padding: 5,
    margin: 5,
    border: "1px solid black",
  },
  topBar: {
    display: "flex",
    justifyContent: "between",
    alignItems: "center",
    flexDirection: "row",
    // margin: 10,
    // padding: 10,
    // flexGrow: 1,
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
    marginBottom: 10,
  },
  GSTtext: {
    fontSize: 12,
    marginBottom: 5,
    textAlign: "left",
    width: "50%",
    fontWeight: "bold",
  },
  fileTypetext: {
    fontSize: 12,
    marginBottom: 5,
    textAlign: "right",
    width: "50%",
  },
  InputTaxtText: {
    fontSize: 9,
    marginBottom: 5,
    textAlign: "center",
    width: "100%",
  },
  TaxInvoiceText: {
    fontSize: 12,
    marginBottom: 5,
    textAlign: "center",
    width: "100%",
    fontWeight: "bold",
  },
  companyDetails: {
    display: "flex",
    justifyContent: "between",
    alignItems: "center",
    flexDirection: "col",
    width: "100%",
    borderBottom: "1px solid black",
    // margin: 10,
    // padding: 10,
    // flexGrow: 1,
  },
  companyName: {
    fontSize: 16,
    marginBottom: 5,
    textAlign: "center",
    width: "100%",
    fontWeight: "bold",
  },
  companyAddress: {
    fontSize: 10,
    marginBottom: 5,
    textAlign: "center",
    width: "100%",
  },
  companyPAN: {
    fontSize: 11,
    marginBottom: 5,
    textAlign: "center",
    width: "100%",
    fontWeight: "bold",
  },

  invoiceDetails: {
    display: "flex",
    justifyContent: "between",
    alignItems: "center",
    flexDirection: "row",
    width: "100%",
  },
  invoiceInfo: {
    textAlign: "left",
    width: "50%",
    paddingLeft: 5,
  },
  invoiceCode: {
    textAlign: "left",
    width: "50%",
    borderLeft: "1px solid black",
    paddingLeft: 5,
  },

  buyerSellerDetails: {
    display: "flex",
    justifyContent: "between",
    alignItems: "center",
    flexDirection: "row",
    width: "100%",
    borderBottom: "1px solid black",
    borderTop: "1px solid black",
    // margin: 10,
    // padding: 10,
    // flexGrow: 1,
  },
  sellerDetails: {
    textAlign: "left",
    width: "50%",
  },

  sellerText: {
    fontSize: 12,
    marginBottom: 5,
    textAlign: "left",
    fontWeight: "bold",
  },
  buyerDetails: {
    textAlign: "left",
    width: "50%",
    borderLeft: "1px solid black",
    paddingLeft: 5,
  },
  buyerText: {
    fontSize: 12,
    marginBottom: 5,
    textAlign: "left",
    width: "100%",
    fontWeight: "bold",
  },

  table: {
    display: "table",
    width: "100%",
    // borderStyle: 'solid',
    // borderWidth: 1,
    // borderCollapse: 'collapse',
    marginBottom: 10,
  },
  tableRow: {
    flexDirection: "row",
    // borderBottomWidth: 1,
    alignItems: "center",
    height: 24,
  },
  tableCol: {
    width: "20%",
    // borderStyle: 'solid',
    borderBottom: "1px solid black",
    // borderWidth: 1,
    height: 24,
    justifyContent: "center",
    alignItems: "center",
  },
  tableHeaderCol: {
    backgroundColor: "#f0f0f0",
    fontWeight: "bold",
  },
  tableCell: {
    textAlign: "center",
    fontSize: "10px",
  },
  qrCode: {
    marginTop: 10,
    alignSelf: "center",
  },
});

const Invoice = () => {
  return (
    <Document>
      <Page size="A4">
        <View style={styles.page}>
          <View style={styles.topBar}>
            <Text style={styles.GSTtext}>GSTIN : 09CHLPG2589Q2ZC</Text>
            <Text style={styles.fileTypetext}> Original Copy</Text>
          </View>
          <View style={styles.topBar}>
            <Text style={styles.InputTaxtText}>
              ( Input Tax Credit is available to a taxable person against this
              copy )
            </Text>
          </View>
          <View style={styles.topBar}>
            <Text style={styles.TaxInvoiceText}>TAX INVOICE</Text>
          </View>
          <View style={styles.companyDetails}>
            <Text style={styles.companyName}> R N Industries</Text>
            <Text style={styles.companyAddress}>
              {" "}
              Khata No 425, Village Sisona Sailab, Tehsil Gannur, Distt. Sambhal
              Uttar Pradesh
            </Text>
            <Text style={styles.companyPAN}>PAN : CHLPG2589Q</Text>
          </View>

          <View style={styles.invoiceDetails}>
            <View style={styles.invoiceInfo}>
              <Text style={styles.GSTtext}>Invoice NO : #1112</Text>
              <Text style={styles.GSTtext}>Date NO : #1112</Text>
              <Text style={styles.GSTtext}>Place : #1112</Text>
              <Text style={styles.GSTtext}>Transport NO : #1112</Text>
              <Text style={styles.GSTtext}>Vehicle NO : #1112</Text>
              <Text style={styles.GSTtext}>Station NO : #1112</Text>
              <Text style={styles.GSTtext}>E-Way Bill NO : #1112</Text>
              <Text style={styles.GSTtext}>E-Way Bill NO : #1112</Text>
              <Text style={styles.GSTtext}>E-Way Bill NO : #1112</Text>
            </View>

            <View style={styles.invoiceCode}>
              <Text style={styles.companyName}> QR</Text>
              <Text style={styles.companyAddress}>
                <View style={styles.qrCode}>
                  {/* <Image src={<QRCode value="123123" />} /> */}

                  {/* <Image src={qrCodeDataURL} style={styles.qrCodeImage} /> */}
                  <View style={styles.qrCodeContainer}>
     
                  </View>
                </View>
                sil Gannur, Distt. Sambhal
              </Text>
              <Text style={styles.companyPAN}>PAN : CHLPG2589Q</Text>
            </View>
          </View>

          <View style={styles.buyerSellerDetails}>
            <View style={styles.sellerDetails}>
              <Text style={styles.sellerText}>Seller:-</Text>
              <Text style={styles.GSTtext}>Name Of Company</Text>
              <Text style={styles.GSTtext}>Address</Text>
              <Text style={styles.GSTtext}>GSTIN / UIN :- </Text>
            </View>

            <View style={styles.buyerDetails}>
              <Text style={styles.buyerText}>Buyer:-</Text>
              <Text style={styles.GSTtext}>Name Of Company</Text>
              <Text style={styles.GSTtext}>Address</Text>
              <Text style={styles.GSTtext}>GSTIN / UIN :- </Text>
            </View>
          </View>

          <View style={styles.section}>
            <View style={styles.table}>
              {/* Table Header */}
              <View style={[styles.tableRow, styles.tableHeaderRow]}>
                <View style={[styles.tableCol, styles.tableHeaderCol]}>
                  <Text style={styles.tableCell}>S.N.</Text>
                </View>
                <View style={[styles.tableCol, styles.tableHeaderCol]}>
                  <Text style={styles.tableCell}>Description of Goods</Text>
                </View>
                <View style={[styles.tableCol, styles.tableHeaderCol]}>
                  <Text style={styles.tableCell}>HSN/SAC Code</Text>
                </View>
                <View style={[styles.tableCol, styles.tableHeaderCol]}>
                  <Text style={styles.tableCell}>Qty.</Text>
                </View>
                <View style={[styles.tableCol, styles.tableHeaderCol]}>
                  <Text style={styles.tableCell}>Unit Price</Text>
                </View>
                <View style={[styles.tableCol, styles.tableHeaderCol]}>
                  <Text style={styles.tableCell}>Amount()</Text>
                </View>
              </View>

              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>1.</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>Black Carbon</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>28030010</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>3,090.00 Kgs.</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>5.00</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>15,450.00</Text>
                </View>
              </View>
            </View>
          </View>
          {/* Total amount */}
          <View style={styles.section}>
            <Text style={styles.text}>Grand Total: 18,231.00</Text>
            {/* Add more text components for tax details */}
            {/* Rupees in words */}
          </View>
          {/* Bank Details */}
          <View style={styles.section}>
            <Text style={styles.text}>
              Bank Details: HDFC Bank Ltd, Anoopshahr Branch, Distt. Bulandshahr
            </Text>
            {/* Add more text components for terms and conditions */}
            {/* E-Invoice QR Code and Receiver's Signature */}
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default Invoice;
