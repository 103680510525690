import React, { useState } from "react";
import axios from "axios";
import { apiurl } from "../config/config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { setCookie } from "../config/coockies";
import { Link } from "react-router-dom";
import LoaderSpiner from "../components/loader";
import Layout from "../components/layout";
const Register = () => {
  const Navigate = useNavigate();
  const [isSignUp, setIsSignUp] = useState(false);
  const [handle, setHandle] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    firstname: "",
    lastname: "",
    phone: "",
    dob: "",
  });
  const initialerror = {
    email: false,
    password: false,
    firstname: false,
    lastname: false,
    phone: false,
    dob: false,
  };
  const [error, setError] = useState(initialerror);
  const toggleForm = () => {
    setIsSignUp(!isSignUp);
  };

  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    console.log(formData);
  };
  const errorHandler = (e) => {
    if (e.target.name === "email") {
      const emailPattern = /\S+@\S+\.\S+/;
      if (!emailPattern.test(e.target.value)) {
        setError({ ...error, [e.target.name]: true });
      } else {
        setError({ ...error, [e.target.name]: "valid" });
      }
    } else {
      if (e.target.value) {
        setError({ ...error, [e.target.name]: "valid" });
      } else {
        setError({ ...error, [e.target.name]: true });
      }
    }
  };
  const handleFileChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.files[0].name });
    console.log(formData);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const { firstname, lastname, email, phone, dob, password } = formData;
      if (
        firstname !== "" &&
        lastname !== "" &&
        email !== "" &&
        email.includes("@") &&
        phone !== "" &&
        dob !== "" &&
        password !== ""
      ) {
        const response = await axios({
          url: `${apiurl}/user/create`,
          method: "POST",
          data: formData,
          headers: {
            "Content-Type": "application/json",
          },
        });
        console.log(response);
        if (response.data.success === true) {
          setLoading(false);
          toast.success(
            `${response.data.message} Please check your Mail for the Email Verification`
          );
          // navigate('/login', { state: { email: formData.email } })
          navigate("/verifyemail", { state: { email: formData.email } });
          formData({});
        } else if (response.data.success === false) {
          setLoading(false);
          toast.error(response?.data?.message);
        }
      } else {
        setLoading(false);
        Object.keys(formData).forEach((fieldname) => {
          if (formData[fieldname].trim() === "") {
            setError((error) => ({
              ...error,
              [fieldname]: true,
            }));
          }
        });
      }
    } catch (error) {
      setLoading(false);
      console.error("Error:", error);
    }
  };

  return (
    <Layout>
    <ToastContainer/>
      {loading === true ? <LoaderSpiner text="Loading ..." /> : null}
      <div className="flex flex-col items-center justify-center login-screen min-h-screen">
        <div className="relative rounded-[20px] w-[100%] sm:min-h-screen h-full max-w-[100%] flex justify-center items-center my-3">
          <div className="md:w-1/2 bg-[#fff] w-full sm:min-h-screen overflow-auto sm:rounded-[20px] sm:rounded-tl-[30px] sm:rounded-bl-[30px] bottomright_bg flex items-center justify-center">
            <div className="w-full px-4 lg:px-16 pt-5 topright_bg">
              <p className="text-center text-[20px]  font-[500] mt-3 mb-3">
                <img
                  src="/assets/savageseller-logo.jpg"
                  alt="logo"
                  className="w-[50%] h-auto mx-auto"
                />
              </p>
              <div className="h2 text-center font-sans text-2xl font-bold leading-46 tracking-normal pb-4">
                Sign up into your account
              </div>
              <div className="grid grid-cols-1 sm:grid-cols-2">
                <div className="col-span-1 py-4 relative">
                  {error.firstname !== "valid" && error.firstname == true ? (
                    <p className="text-[#FF0000] text-sm absolute -top-2 left-2">
                      First name is mandatory *
                    </p>
                  ) : null}
                  <input
                    type="text"
                    name="firstname"
                    placeholder="First Name"
                    className="w-[95%] bg-[#EFF5FF] placeholder-slate-700 placeholder:bg-[#EFF5FF] focus:bg-[#EFF5FF] outline-none m-auto block p-2 border border-[#f0f0f0]"
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    onBlur={(e) => errorHandler(e)}
                  />
                </div>
                <div className="col-span-1 py-4 relative">
                  {error.lastname !== "valid" && error.lastname == true ? (
                    <p className="text-[#FF0000] text-sm absolute -top-2 left-2">
                      Last name is mandatory *
                    </p>
                  ) : null}
                  <input
                    type="text"
                    name="lastname"
                    placeholder="Last Name"
                    className="w-[95%] bg-[#EFF5FF] placeholder-slate-700 placeholder:bg-[#EFF5FF] focus:bg-[#EFF5FF] outline-none m-auto block p-2 border border-[#f0f0f0]"
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    onBlur={(e) => errorHandler(e)}
                  />
                </div>
              </div>
              <div className="grid grid-cols-1">
                <div className="col-span-1 py-4 relative">
                  {error.email !== "valid" && error.email == true ? (
                    <p className="text-[#FF0000] text-sm absolute -top-2 left-2 w-[80%]">
                      Email is mandatory with @*
                    </p>
                  ) : null}
                  <input
                    type="email"
                    name="email"
                    placeholder="Email"
                    className="w-[98%] bg-[#EFF5FF] placeholder-slate-700 placeholder:bg-[#EFF5FF] focus:bg-[#EFF5FF] outline-none m-auto block p-2 border border-[#f0f0f0]"
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    onBlur={(e) => errorHandler(e)}
                  />
                </div>
              </div>
              <div className="grid grid-cols-1 sm:grid-cols-2">
                <div className="col-span-1 py-4 relative">
                  {error.phone !== "valid" && error.phone == true ? (
                    <p className="text-[#FF0000] text-sm absolute -top-2 left-2 w-[80%]">
                      Mobile number is mandatory *
                    </p>
                  ) : null}
                  <input
                    type="text"
                    name="phone"
                    placeholder="Mobile Number"
                    className="w-[95%] bg-[#EFF5FF] placeholder-slate-700 placeholder:bg-[#EFF5FF] focus:bg-[#EFF5FF] outline-none m-auto block p-2 border border-[#f0f0f0]"
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    onBlur={(e) => errorHandler(e)}
                  />
                </div>
                <div className="col-span-1 py-4 relative">
                  {error.dob !== "valid" && error.dob == true ? (
                    <p className="text-[#FF0000] text-sm absolute -top-2 left-2 w-[80%]">
                      D.O.B. is mandatory *
                    </p>
                  ) : null}
                  <input
                    type="date"
                    name="dob"
                    placeholder="Enter your D.O.B."
                    className="w-[95%] bg-[#EFF5FF] placeholder-slate-700 placeholder:bg-[#EFF5FF] focus:bg-[#EFF5FF] outline-none m-auto block p-2 border border-[#f0f0f0]"
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    onBlur={(e) => errorHandler(e)}
                  />
                </div>
              </div>
              <div className="grid grid-cols-1 sm:grid-cols-2">
                <div className="col-span-1 py-4 relative">
                  {error.password !== "valid" && error.password == true ? (
                    <p className="text-[#FF0000] text-sm absolute -top-2 left-2 w-[80%]">
                      Password is mandatory *
                    </p>
                  ) : null}
                  <input
                    type="password"
                    name="password"
                    placeholder="Password"
                    className="w-[95%] bg-[#EFF5FF] placeholder-slate-700 placeholder:bg-[#EFF5FF] focus:bg-[#EFF5FF] outline-none m-auto block p-2 border border-[#f0f0f0]"
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    onBlur={(e) => errorHandler(e)}
                  />
                </div>
              </div>
              <div className="grid grid-cols-1">
                <div className="col-span-1">
                  <button
                    className="rounded-md bg-[#005AE6] text-[#fff] text-[20px] font-[500] p-2 m-auto block mt-6 w-[95%]"
                    onClick={handleSubmit}
                  >
                    Sign Up
                  </button>
                </div>
              </div>
              <p className="text-center  font-[500] mt-3 p-3 block ">
                Already have account?{" "}
                <Link to="/login">
                  <span className="underline text-[#005AE6] cursor-pointer">
                    Login
                  </span>
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Register;
