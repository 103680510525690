import React, { useEffect } from "react";
import { useState } from "react";
import Layout from "../components/layout";
import axios from "axios";
import { Link } from "react-router-dom";
import { useUser } from "../config/UserProvider";
import { apiurl } from "../config/config";
import Checkout from "../components/checkout";

const CartPage = () => {
  const { userData } = useUser();
  const [course, setCourse] = useState([]);
  const [price, setPrice] = useState({});

  console.log("userData cart", userData);
  let userId = userData?._id;

  const prefill = {
    name: userData?.name,
    email: userData?.email,
    contact: userData?.phone,
  };

  let fetchCartDetails = (userId) => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${apiurl}/user/cart/${userId}`,
      headers: {
        Cookie: "JSESSIONID.dff707c7=node04h51mbtjf88tc6mmaulc123o0.node0",
      },
    };
    axios
      .request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        setCourse(response?.data?.cartCourses);
        setPrice(response?.data?.price);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchCartDetails(userId);
  }, [userId]);
  return (
    <Layout>
      <div class="container mx-auto mt-10">
        <div class="sm:flex shadow-md my-10">
          <div class="  w-full  sm:w-3/4 bg-white px-10 py-10">
            <div class="flex justify-between border-b pb-8">
              <h1 class="font-semibold text-2xl">Shopping Cart</h1>
              <h2 class="font-semibold text-2xl">{course?.length}</h2>
            </div>
            {course &&
              course.map((item) => {
                return (
                  <Link
                    to={`/courses/${item?._id}`}
                    class="md:flex items-strech py-8 md:py-10 lg:py-8 border-t border-gray-50"
                  >
                    <div class="md:w-4/12 2xl:w-1/4 w-full">
                      <img
                        src="https://i.ibb.co/6gzWwSq/Rectangle-20-1.png"
                        alt="Black Leather Purse"
                        class="h-full object-center object-cover md:block hidden"
                      />
                      <img
                        src="https://i.ibb.co/TTnzMTf/Rectangle-21.png"
                        alt="Black Leather Purse"
                        class="md:hidden w-full h-full object-center object-cover"
                      />
                    </div>
                    <div class="md:pl-3 md:w-8/12 2xl:w-3/4 flex flex-col justify-center">
                      <p class="text-xs leading-3 text-gray-800 md:pt-0 pt-4">
                        {item?.courseCode}
                      </p>
                      <div class="flex items-center justify-between w-full">
                        <p class="text-base font-black leading-none text-gray-800">
                          {item?.courseName}
                        </p>
                        {/* <select
                    aria-label="Select quantity"
                    class="py-2 px-1 border border-gray-200 mr-6 focus:outline-none"
                  >
                    <option>01</option>
                    <option>02</option>
                    <option>03</option>
                  </select> */}
                      </div>
                      <p class="text-xs leading-3 text-gray-600 pt-2">
                        {item?.duration}
                      </p>
                      <p class="text-xs leading-3 text-gray-600 py-4">
                        {item?.language}
                      </p>
                      <p class="w-96 text-xs leading-3 text-gray-600">
                        Composition: 100% calf leather
                      </p>
                      <div class="flex items-center justify-between pt-5">
                        <div class="flex itemms-center">
                          <p class="text-xs leading-3 underline text-gray-800 cursor-pointer">
                            {item?.level}
                          </p>
                          <p class="text-xs leading-3 underline text-red-500 pl-5 cursor-pointer">
                            Remove
                          </p>
                        </div>
                        <p class="text-base font-black leading-none text-gray-800">
                          Rs {item?.fee}
                        </p>
                      </div>
                    </div>
                  </Link>
                );
              })}

            <Link
              to="/courses"
              class="flex font-semibold text-indigo-600 text-sm mt-10"
            >
              <svg
                class="fill-current mr-2 text-indigo-600 w-4"
                viewBox="0 0 448 512"
              >
                <path d="M134.059 296H436c6.627 0 12-5.373 12-12v-56c0-6.627-5.373-12-12-12H134.059v-46.059c0-21.382-25.851-32.09-40.971-16.971L7.029 239.029c-9.373 9.373-9.373 24.569 0 33.941l86.059 86.059c15.119 15.119 40.971 4.411 40.971-16.971V296z" />
              </svg>
              Continue Shopping
            </Link>
          </div>
          <div
            id="summary"
            class=" w-full   sm:w-1/4   md:w-1/2     px-8 py-10"
          >
            <h1 class="font-semibold text-2xl border-b pb-8">Order Summary</h1>
            <div class="flex justify-between mt-10 mb-5">
              <span class="font-semibold text-sm uppercase">
                Items {course?.length}
              </span>
              <span class="font-semibold text-sm">₹ {price?.subtotal}</span>
            </div>
            <div class="flex justify-between mt-10 mb-5">
              <span class="font-semibold text-sm uppercase">GST 18%</span>
              <span class="font-semibold text-sm">₹ {price?.gst}</span>
            </div>
            {/* <div>
              <label class="font-medium inline-block mb-3 text-sm uppercase">
                Shipping
              </label>
              <select class="block p-2 text-gray-600 w-full text-sm">
                <option>Standard shipping - $10.00</option>
              </select>
            </div> */}
            {/* <div class="py-10">
              <label
                for="promo"
                class="font-semibold inline-block mb-3 text-sm uppercase"
              >
                Promo Code
              </label>
              <input
                type="text"
                id="promo"
                placeholder="Enter your code"
                class="p-2 text-sm w-full"
              />
            </div>
            <button class="bg-red-500 hover:bg-red-600 px-5 py-2 text-sm text-white uppercase">
              Apply
            </button>
             */}
            <div class="border-t mt-8">
              <div class="flex font-semibold justify-between py-6 text-sm uppercase">
                <span>Total cost</span>
                <span>₹ {price?.totalPrice}</span>
              </div>
              <Checkout
                courseId={`1212`}
                amount={price?.totalPrice}
                prefill={prefill}
              />
            </div>
          </div>
        </div>
      </div>

      {/* 
        <div class="h-screen bg-gray-300">
          <div class="py-12">
            <div class="max-w-md mx-auto bg-gray-100 shadow-lg rounded-lg  md:max-w-5xl">
              <div class="md:flex ">
                <div class="w-full p-4 px-5 py-5">
                  <div class="md:grid md:grid-cols-3 gap-2 ">
                    <div class="col-span-2 p-5">
                      <h1 class="text-xl font-medium ">Shopping Cart</h1>

                      <div class="flex justify-between items-center mt-6 pt-6">
                        <div class="flex  items-center">
                          <img
                            src="https://i.imgur.com/EEguU02.jpg"
                            width="60"
                            class="rounded-full "
                          />

                          <div class="flex flex-col ml-3">
                            <span class="md:text-md font-medium">
                              Chicken momo
                            </span>
                            <span class="text-xs font-light text-gray-400">
                              #41551
                            </span>
                          </div>
                        </div>

                        <div class="flex justify-center items-center">
                          <div class="pr-8 flex ">
                            <span class="font-semibold">-</span>
                            <input
                              type="text"
                              class="focus:outline-none bg-gray-100 border h-6 w-8 rounded text-sm px-2 mx-2"
                              value="1"
                            />
                            <span class="font-semibold">+</span>
                          </div>

                          <div class="pr-8 ">
                            <span class="text-xs font-medium">$10.50</span>
                          </div>
                          <div>
                            <i class="fa fa-close text-xs font-medium"></i>
                          </div>
                        </div>
                      </div>

                      <div class="flex justify-between items-center pt-6 mt-6 border-t">
                        <div class="flex  items-center">
                          <img
                            src="https://i.imgur.com/Uv2Yqzo.jpg"
                            width="60"
                            class="rounded-full "
                          />

                          <div class="flex flex-col ml-3 ">
                            <span class="text-md font-medium w-auto">
                              Spicy Mexican potatoes
                            </span>
                            <span class="text-xs font-light text-gray-400">
                              #66999
                            </span>
                          </div>
                        </div>

                        <div class="flex justify-center items-center">
                          <div class="pr-8 flex">
                            <span class="font-semibold">-</span>
                            <input
                              type="text"
                              class="focus:outline-none bg-gray-100 border h-6 w-8 rounded text-sm px-2 mx-2"
                              value="1"
                            />
                            <span class="font-semibold">+</span>
                          </div>

                          <div class="pr-8">
                            <span class="text-xs font-medium">$10.50</span>
                          </div>
                          <div>
                            <i class="fa fa-close text-xs font-medium"></i>
                          </div>
                        </div>
                      </div>

                      <div class="flex justify-between items-center mt-6 pt-6 border-t">
                        <div class="flex  items-center">
                          <img
                            src="https://i.imgur.com/xbTAITF.jpg"
                            width="60"
                            class="rounded-full "
                          />

                          <div class="flex flex-col ml-3 ">
                            <span class="text-md font-medium">Breakfast</span>
                            <span class="text-xs font-light text-gray-400">
                              #86577
                            </span>
                          </div>
                        </div>

                        <div class="flex justify-center items-center">
                          <div class="pr-8 flex">
                            <span class="font-semibold">-</span>
                            <input
                              type="text"
                              class="focus:outline-none bg-gray-100 border h-6 w-8 rounded text-sm px-2 mx-2"
                              value="1"
                            />
                            <span class="font-semibold">+</span>
                          </div>

                          <div class="pr-8">
                            <span class="text-xs font-medium">$10.50</span>
                          </div>
                          <div>
                            <i class="fa fa-close text-xs font-medium"></i>
                          </div>
                        </div>
                      </div>

                      <div class="flex justify-between items-center mt-6 pt-6 border-t">
                        <div class="flex items-center">
                          <i class="fa fa-arrow-left text-sm pr-2"></i>
                          <span class="text-md  font-medium text-blue-500">
                            Continue Shopping
                          </span>
                        </div>

                        <div class="flex justify-center items-end">
                          <span class="text-sm font-medium text-gray-400 mr-1">
                            Subtotal:
                          </span>
                          <span class="text-lg font-bold text-gray-800 ">
                            {" "}
                            $24.90
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class=" p-5 bg-gray-800 rounded overflow-visible">
                      <span class="text-xl font-medium text-gray-100 block pb-3">
                        Card Details
                      </span>

                      <span class="text-xs text-gray-400 ">Card Type</span>

                      <div class="overflow-visible flex justify-between items-center mt-2">
                        <div class="rounded w-52 h-28 bg-gray-500 py-2 px-4 relative right-10">
                          <span class="italic text-lg font-medium text-gray-200 underline">
                            VISA
                          </span>

                          <div class="flex justify-between items-center pt-4 ">
                            <span class="text-xs text-gray-200 font-medium">
                              ****
                            </span>
                            <span class="text-xs text-gray-200 font-medium">
                              ****
                            </span>
                            <span class="text-xs text-gray-200 font-medium">
                              ****
                            </span>
                            <span class="text-xs text-gray-200 font-medium">
                              ****
                            </span>
                          </div>

                          <div class="flex justify-between items-center mt-3">
                            <span class="text-xs  text-gray-200">
                              Giga Tamarashvili
                            </span>
                            <span class="text-xs  text-gray-200">12/18</span>
                          </div>
                        </div>

                        <div class="flex justify-center  items-center flex-col">
                          <img
                            src="https://img.icons8.com/color/96/000000/mastercard-logo.png"
                            width="40"
                            class="relative right-5"
                          />
                          <span class="text-xs font-medium text-gray-200 bottom-2 relative right-5">
                            mastercard.
                          </span>
                        </div>
                      </div>

                      <div class="flex justify-center flex-col pt-3">
                        <label class="text-xs text-gray-400 ">
                          Name on Card
                        </label>
                        <input
                          type="text"
                          class="focus:outline-none w-full h-6 bg-gray-800 text-white placeholder-gray-300 text-sm border-b border-gray-600 py-4"
                          placeholder="Giga Tamarashvili"
                        />
                      </div>

                      <div class="flex justify-center flex-col pt-3">
                        <label class="text-xs text-gray-400 ">
                          Card Number
                        </label>
                        <input
                          type="text"
                          class="focus:outline-none w-full h-6 bg-gray-800 text-white placeholder-gray-300 text-sm border-b border-gray-600 py-4"
                          placeholder="****     ****      ****      ****"
                        />
                      </div>

                      <div class="grid grid-cols-3 gap-2 pt-2 mb-3">
                        <div class="col-span-2 ">
                          <label class="text-xs text-gray-400">
                            Expiration Date
                          </label>
                          <div class="grid grid-cols-2 gap-2">
                            <input
                              type="text"
                              class="focus:outline-none w-full h-6 bg-gray-800 text-white placeholder-gray-300 text-sm border-b border-gray-600 py-4"
                              placeholder="mm"
                            />
                            <input
                              type="text"
                              class="focus:outline-none w-full h-6 bg-gray-800 text-white placeholder-gray-300 text-sm border-b border-gray-600 py-4"
                              placeholder="yyyy"
                            />
                          </div>
                        </div>

                        <div class="">
                          <label class="text-xs text-gray-400">CVV</label>
                          <input
                            type="text"
                            class="focus:outline-none w-full h-6 bg-gray-800 text-white placeholder-gray-300 text-sm border-b border-gray-600 py-4"
                            placeholder="XXX"
                          />
                        </div>
                      </div>

                      <button class="h-12 w-full bg-blue-500 rounded focus:outline-none text-white hover:bg-blue-600">
                        Check Out
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
    </Layout>
  );
};

export default CartPage;
