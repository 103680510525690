import React, {useRef} from "react";
import Layout from "../components/layout";
import Certificate from "../components/certificate";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

const Topic = () => {
  const certificateRef = useRef();

  // const downloadImage = () => {
  //   html2canvas(certificateRef.current).then(canvas => {
  //     const link = document.createElement('a');
  //     link.href = canvas.toDataURL('image/png');
  //     link.download = 'certificate.png';
  //     link.click();
  //   });
  // };

  // const downloadPDF = () => {
  //   html2canvas(certificateRef.current).then(canvas => {
  //     const imgData = canvas.toDataURL('image/png');
  //     const pdf = new jsPDF();
  //     pdf.addImage(imgData, 'PNG', 0, 0);
  //     pdf.save('certificate.pdf');
  //   });
  // };


  // const downloadImage = async () => {
  //   const canvas = await html2canvas(certificateRef.current);
  //   const link = document.createElement('a');
  //   link.href = canvas.toDataURL('image/png');
  //   link.download = 'certificate.png';
  //   link.click();
  // };

  // const downloadPDF = async () => {
  //   const canvas = await html2canvas(certificateRef.current);
  //   const imgData = canvas.toDataURL('image/png');
  //   const pdf = new jsPDF();
  //   const imgProps = pdf.getImageProperties(imgData);
  //   const pdfWidth = pdf.internal.pageSize.getWidth();
  //   const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
  //   pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
  //   pdf.save('certificate.pdf');
  // };


  const width = 900; // Set your desired width
  const height = 760; // Set your desired height

  // const downloadImage = async () => {
  //   const canvas = await html2canvas(certificateRef.current, {
  //     width: width,
  //     height: height,
  //   });
  //   const link = document.createElement('a');
  //   link.href = canvas.toDataURL('image/png');
  //   link.download = 'certificate.png';
  //   link.click();
  // };

  const downloadImage = async () => {
    const canvas = await html2canvas(certificateRef.current, {
      scale: 2,
      useCORS: true,
      allowTaint: true,
      width: width,
      height: height,
      scrollX: -window.scrollX,
      scrollY: -window.scrollY,
      windowWidth: document.documentElement.offsetWidth,
      windowHeight: document.documentElement.offsetHeight
    });
    const link = document.createElement('a');
    link.href = canvas.toDataURL('image/png');
    link.download = 'certificate.png';
    link.click();
  };

  const downloadPDF = async () => {
    const canvas = await html2canvas(certificateRef.current, {
      width: width,
      height: height,
    });
    const imgData = canvas.toDataURL('image/png');
    const pdf = new jsPDF({
      orientation: 'landscape',
      unit: 'px',
      format: [width, height]
    });
    pdf.addImage(imgData, 'PNG', 0, 0, width, height);
    pdf.save('certificate.pdf');
  };

  const user = {
    name: "ASRAFUL ISLAM",
  };

  const course = "HTML, CSS, JavaScript";

  const certId = "EUIUX-22050314";
  const issueDate = "22/02/2023";

  const partners = [
    { logo: 'path/to/partner1-logo.png', name: 'Partner 1 Name' },
    { logo: 'path/to/partner2-logo.png', name: 'Partner 2 Name' },
  ];

  return (
    <Layout>
      <div className="p-5 bg-red-100 m-5 rounded-[1.5rem] relative">
        <p className="lg:text-[48px] text-[20px] font-semibold text-l md:text-5xl my-3">
          Kickstart your career{" "}
        </p>
        <p className="lg:text-[48px] text-[20px] font-semibold text-l md:text-5xl my-3">
          <span className="text-[#FF7426]">Get certified </span>by completing
          the HTML course
        </p>
        <button className="bg-orange-400 py-3 md:py-4 md:text-2xl font-medium px-5 md:px-16 rounded-2xl mb-3 my-5">
          Get Certified
        </button>
        <img
          src="/Group2.png"
          alt=""
          class="absolute left-0 top-32 w-8 md:w-14"
        ></img>
      </div>

      {/* <div className="bg-gray-100 min-h-screen p-6">
      <Certificate
        name="HEMANT JAIN"
        date="DATE"
        certId="xxxxx"
        issuedDate="xxxxx"
      />
    </div> */}
    {/* <div className="bg-gray-100 min-h-screen p-6">

    <Certificate
        name="ASRAFUL ISLAM"
        id="EUIUX-22050314"
        date="22/02/2023"
        partners={[
          { logo: 'path/to/partner1-logo.png', name: 'Partner 1 Name' },
          { logo: 'path/to/partner2-logo.png', name: 'Partner 2 Name' },
        ]}
      />
    </div> */}





    {/* <div className="bg-gray-100 min-h-screen p-6">
      <Certificate
        user={user}
        course={course}
        certId={certId}
        issueDate={issueDate}
        partners={partners}
      />
    </div>
    <div className="bg-gray-100 min-h-screen p-6">
      <div ref={certificateRef}>
        <Certificate user={user} course={course} certId={certId} issueDate={issueDate} partners={partners} />
      </div>
      <div className="mt-4">
        <button onClick={downloadImage} className="mr-2 px-4 py-2 bg-blue-500 text-white rounded">Download as Image</button>
        <button onClick={downloadPDF} className="px-4 py-2 bg-green-500 text-white rounded">Download as PDF</button>
      </div>
    </div> */}


    <div className="bg-gray-100 p-5">
      <div 
        ref={certificateRef} 
        className="p-4 bg-white shadow-lg rounded" 
        style={{ width: `${width}px`, height: `${height}px` }}
      >
        <Certificate 
          user={user} 
          course={course} 
          certId={certId} 
          issueDate={issueDate} 
          partners={partners} 
        />
      </div>
      <div className="mt-4">
        <button onClick={downloadImage} className="mr-2 px-4 py-2 bg-blue-500 text-white rounded">
          Download as Image
        </button>
        <button onClick={downloadPDF} className="px-4 py-2 bg-green-500 text-white rounded">
          Download as PDF
        </button>
      </div>
    </div>

    </Layout>
  );
};
export default Topic;
