import { useState } from "react";
import { LiaShoppingCartSolid } from "react-icons/lia";
import { CiSearch } from "react-icons/ci";
import Layout from "../components/layout";
import { Link } from "react-router-dom";
// import girlImage from "../assets/images/portrait-cute-little-girl-with-glasses-striped-t-shirt-with-notebooks-textbooks-her-hands-backpack-concept-education-photo-studio-yellow-background-space-text 1.png";
// import vector from "../assets/images/graph.png";
// import triangles from "../assets/images/Vector.png";
// import plus from "../Vector-2.png";
// import redCircle from "../Vector-3.png";
// import bee from "../bee 1.png";
// import rnd1 from "../Group1.png";
// import rnd2 from "../Group2.png";

const Courses = () => {
  const unSplashURL = "https://source.unsplash.com/user/wsanter";
  const mathCourses = [
    {
      title: " Math course for 3rd grade children",
      offerPrice: "$380",
      orignalPrice: "$500",
      description:
        "Course summarizing semester 1 knowledge for 3rd graders according to the program at school. The course includes 120 lessons, which students study for 3 months",
      imageSrc: unSplashURL,
    },
    {
      title: " Math course for 3rd grade children",
      offerPrice: "$380",
      orignalPrice: "$500",
      description:
        "Course summarizing semester 1 knowledge for 3rd graders according to the program at school. The course includes 120 lessons, which students study for 3 months",
      imageSrc: unSplashURL,
    },
    {
      title: " Math course for 3rd grade children",
      offerPrice: "$380",
      orignalPrice: "$500",
      description:
        "Course summarizing semester 1 knowledge for 3rd graders according to the program at school. The course includes 120 lessons, which students study for 3 months",
      imageSrc: unSplashURL,
    },
    {
      title: " Math course for 3rd grade children",
      offerPrice: "$380",
      orignalPrice: "$500",
      description:
        "Course summarizing semester 1 knowledge for 3rd graders according to the program at school. The course includes 120 lessons, which students study for 3 months",
      imageSrc: unSplashURL,
    },
  ];

  const literatureCourse = [
    {
      title: " Math course for 3rd grade children",
      offerPrice: "$380",
      orignalPrice: "$500",
      description:
        "Course summarizing semester 1 knowledge for 3rd graders according to the program at school. The course includes 120 lessons, which students study for 3 months",
      imageSrc: unSplashURL,
    },
    {
      title: " Math course for 3rd grade children",
      offerPrice: "$380",
      orignalPrice: "$500",
      description:
        "Course summarizing semester 1 knowledge for 3rd graders according to the program at school. The course includes 120 lessons, which students study for 3 months",
      imageSrc: unSplashURL,
    },
    {
      title: " Math course for 3rd grade children",
      offerPrice: "$380",
      orignalPrice: "$500",
      description:
        "Course summarizing semester 1 knowledge for 3rd graders according to the program at school. The course includes 120 lessons, which students study for 3 months",
      imageSrc: unSplashURL,
    },
    {
      title: " Math course for 3rd grade children",
      offerPrice: "$380",
      orignalPrice: "$500",
      description:
        "Course summarizing semester 1 knowledge for 3rd graders according to the program at school. The course includes 120 lessons, which students study for 3 months",
      imageSrc: unSplashURL,
    },
  ];
  return (
    <Layout>
      <div className="bg-gray-50 flex justify-center">
        <div className="w-full flex flex-col items-center px-5">
          <Banner />
          {/* <CourseFilter /> */}
          {/* <div className="flex justify-between w-9/12 my-14"> */}
            {/* <SearchBar /> */}
            {/* <ShortBar /> */}
          {/* </div> */}
          <div className="w-full py-10">
            <div className=" flex justify-between w-full">
              <h1 className="text-4xl font-bold">Math Course</h1>
              <button>see more {">"}</button>
            </div>
            <div className="flex gap-2 w-full justify-between py-12 flex-wrap">
              {mathCourses.map((item, key) => {
                return (
                  <Card
                    key={key}
                    title={item.title}
                    description={item.description}
                    imageSrc={item.imageSrc}
                    offerPrice={item.offerPrice}
                    orignalPrice={item.orignalPrice}
                  />
                );
              })}
            </div>
          </div>
          <div className="w-full py-10">
            <div className=" flex justify-between w-full">
              <h1 className="text-4xl font-bold">Literature Course</h1>
              <button>see more {">"}</button>
            </div>
            <div className="flex gap-2 w-full justify-between py-12 flex-wrap">
              {literatureCourse.map((item, key) => {
                return (
                  <Card
                    key={key}
                    title={item.title}
                    description={item.description}
                    imageSrc={item.imageSrc}
                    offerPrice={item.offerPrice}
                    orignalPrice={item.orignalPrice}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Courses;

export const CourseFilter = () => {
  const courses = ["all Courses", "math", "Literature", "English", "art"];
  const [active, setActive] = useState(courses[0]);

  return (
    <div className="w-3/5 flex justify-evenly mt-10">
      {courses.map((course) => {
        return (
          <button
            key={course}
            onClick={() => setActive(course)}
            className={` ${
              active === course
                ? "bg-black  text-white "
                : "bg-white  text-black"
            } px-10  shadow-lg text-xl py-5 capitalize rounded-xl font-semibold`}
          >
            {course}
          </button>
        );
      })}
    </div>
  );
};

export const ShortBar = () => {
  return (
    <div className="flex items-center bg-white text-xl px-5 py-3 rounded-lg w-72 ">
      <label className="text-gray-400/70 w-1/2 text-lg">Short by :</label>
      <select className="outline-none tracking-wide w-full ">
        <option>latest</option>
      </select>
    </div>
  );
};
export const SearchBar = () => {
  return (
    <form
      onSubmit={{}}
      className="w-1/2 bg-white rounded-lg overflow-hidden py-2 justify-between flex px-5"
    >
      <input
        type="text"
        placeholder="Serach Course, Teacher name"
        className="text-xl  w-full pr-4 py-2 outline-none "
      />
      <button className="p-3 rounded-xl text-3xl text-white bg-orange-500">
        <CiSearch />
      </button>
    </form>
  );
};

export const Banner = () => {
  const RedCircled = ({ radius, style }) => {
    // const  = 10
    return (
      <div
        className={`bg-red-600 rounded-full w-1 ${style} `}
        style={{ padding: radius, height: 1 }}
      ></div>
    );
  };

  const Rect = ({ size, color, className }) => {
    let width = size;
    let height = size * 2;
    return (
      <div
        className={`${color ? color : "bg-red-500"} ${className}`}
        style={{ width: width, height: height }}
      ></div>
    );
  };
  return (
    <div className="relative bg-red-100 w-full rounded-2xl  px-3 pt-3 flex md:px-40 my-6 overflow-hidden">
      <div className="w-full md:w-4/5 flex flex-col gap-2 md:gap-6 md:py-14 md:pl-24">
        <h1 className="font-semibold text-l md:text-5xl">
          Edudu offers you a 30% discount this season
        </h1>
        <p className="text-l">
          Promotion valid from May 1, 2023 - June 30, 2023
        </p>
        <div>
          <img src="/bee.png" className="w-14 -ml-10 -mb-2" />
          <Link to='/contact' className="bg-orange-400 py-3 md:py-6 md:text-2xl font-medium px-6 md:px-16 rounded-2xl mb-3 inline-block">
          Inquiry
          </Link>
        </div>
      </div>
      <div className=" w-2/5 mr-10 h-full flex justify-center items-end">
        <div className="relative">
          <img
            src="/little-girl-with-notebooks.png"
            className="z-10 relative w-96 "
          />

          <svg
            className="absolute -bottom-[3rem] w-[9rem] md:-bottom-[8rem] md:w-[30rem]"
            id="sw-js-blob-svg"
            viewBox="0 0 100 100"
            xmlns="http://www.w3.org/2000/svg"
          >
            {" "}
            <defs>
              {" "}
              <linearGradient id="sw-gradient" x1="0" x2="1" y1="1" y2="0">
                {" "}
                <stop
                  id="stop1"
                  stop-color="rgba(248, 117, 55, 1)"
                  offset="0%"
                ></stop>{" "}
                <stop
                  id="stop2"
                  stop-color="rgba(251, 168, 31, 1)"
                  offset="100%"
                ></stop>{" "}
              </linearGradient>{" "}
            </defs>{" "}
            <path
              fill="url(#sw-gradient)"
              d="M20.8,-37.6C24.9,-33.6,24.7,-23.9,25.3,-16.7C25.9,-9.5,27.2,-4.7,28.3,0.7C29.5,6.1,30.6,12.2,28.3,16.5C26.1,20.7,20.4,23.1,15.1,26.8C9.8,30.5,4.9,35.5,-0.6,36.5C-6.2,37.6,-12.3,34.8,-17.6,31.1C-22.9,27.4,-27.2,22.8,-31.6,17.5C-36.1,12.2,-40.5,6.1,-39.8,0.4C-39,-5.2,-33.1,-10.5,-29.3,-16.9C-25.5,-23.3,-23.9,-30.9,-19.3,-34.6C-14.8,-38.3,-7.4,-38.2,0.5,-39C8.3,-39.8,16.7,-41.5,20.8,-37.6Z"
              width="100%"
              height="100%"
              transform="translate(50 50)"
              stroke-width="0"

              //   style="transition: all 0.3s ease 0s;"
            ></path>{" "}
          </svg>
        </div>
      </div>
      <RedCircled radius={6} style="absolute bottom-10 right-[40%]" />
      <RedCircled radius={6} style="absolute top-[50%] right-[50%]" />

      <Rect
        size={7}
        color="bg-green-500"
        className="absolute top-10 right-[14%] -rotate-12"
      />
      <Rect
        size={7}
        color="bg-red-500"
        className="absolute top-[20%] right-[12%] -rotate-45"
      />
      <Rect
        size={7}
        color="bg-yellow-500"
        className="absolute top-[10%] right-[10%] -rotate-45"
      />
      <Rect
        size={7}
        color="bg-blue-500"
        className="absolute top-[34%] right-[10%] -rotate-12"
      />

      <img
        src="/Vector-2.png"
        alt=""
        className="absolute right-[35%] top-[40%] w-4"
      />
      <img
        src="/Vector-2.png"
        alt=""
        className="absolute left-[45%] top-[60%] w-4"
      />

      <img src="/Vector.png" alt="" className="absolute right-0 top-0 w-24" />
      <img
        src="/Group1.png"
        alt=""
        className="absolute right-4 bottom-4 w-14"
      />
      <img
        src="/Group2.png"
        alt=""
        className="absolute left-10 bottom-10 w-8 md:w-14"
      />
    </div>
  );
};

export const Card = ({
  key,
  imageSrc,
  title,
  description,
  offerPrice,
  orignalPrice,
}) => {
  return (
    <Link
      to="/course"
      key={key}
      className="card flex flex-col items-center w-[22rem] bg-white rounded-xl shadow-lg overflow-hidden"
    >
      <img src={imageSrc} className="bg-green-50 h-52 w-full " />
      <div className="flex items-center gap-3 bg-gray-50 rounded-full -mt-5 px-3 pr-4 py-2 font-medium text-sm">
        <div className="flex flex-row ">
          <span className="bg-yellow-400 rounded-full w-8 h-8  "></span>
          <span className="bg-red-400 rounded-full w-8 h-8 -ml-3 "></span>
          <span className="bg-green-400 rounded-full w-8 h-8 -ml-3"></span>
          <span className="bg-violet-400 rounded-full w-8 h-8 -ml-3"></span>
        </div>
        +40 Students
      </div>
      <div className="py-7 px-2 w-11/12">
        <h5 className="font-semibold text-lg tracking-wide px-1">{title}</h5>
        <p className="text-xs text-gray-400 mt-2 ">{description}</p>
        <div className="flex justify-between items-center w-full mt-5">
          <div>
            <span className="font-bold text-xl text-orange-500">
              {offerPrice}{" "}
            </span>
            <span className="line-through text-lg text-gray-400">
              {orignalPrice}
            </span>
          </div>
          <div className="bg-orange-400/30 p-2 rounded-full">
            <LiaShoppingCartSolid className="text-3xl text-orange-400" />
          </div>
        </div>
      </div>
    </Link>
  );
};
